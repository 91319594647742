import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { User } from '../../models/user';
import { Observable, Subject, Subscription } from 'rxjs';
import { AuthService } from '../../services/auth.service';
import { LoadingService } from '../../services/loading/loading.service';
import { ApplicationHelper } from '../../helpers/application-helper';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { SnackBarService } from '../../services/snack-bar/snack-bar.service';
import { Router } from '@angular/router';
import { Application } from '../../enum-collection';
import { PartnerUser } from '../../models/partners/partner-user';
import { WebServiceWorkerService } from '../../services/web-service-worker-service';

@Component({
  selector: 'lib-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  isNewAppVersionAvailable = false;
  user$: Observable<User | null> = new Observable<User | null>();
  partnerUser$: Observable<PartnerUser | null> =
    new Observable<PartnerUser | null>();
  loading$ = this.loadingService.globalLoading$;
  readonly environment = environment;
  readonly ApplicationHelper = ApplicationHelper;

  private destroy$ = new Subject<void>();
  private subscriptions = new Subscription();

  constructor(
    private authService: AuthService,
    private loadingService: LoadingService,
    private router: Router,
    private snackService: SnackBarService,
    private webServiceWorker: WebServiceWorkerService,
  ) {}

  ngOnInit(): void {
    this.user$ = this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$));

    this.partnerUser$ = this.authService
      .getCurrentPartnerUser()
      .pipe(takeUntil(this.destroy$));

    this.subscriptions.add(this.checkForAppUpdates());
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  logout(): void {
    this.authService.logout().subscribe({
      next: () => {
        this.router.navigate(['/login']);
        this.snackService.openSnackBar(
          'Sie wurden erfolgreich abgemeldet',
          'success',
        );
      },
      error: (err) => {
        console.error('Logout failed', err);
        this.snackService.openSnackBar(
          'Abmeldung fehlgeschlagen. Bitte versuchen Sie es erneut.',
          'error',
        );
      },
    });
  }

  private checkForAppUpdates(): Subscription {
    return this.webServiceWorker.$isAnyNewUpdateAvailable.subscribe(
      (versionAvailable: boolean) => {
        this.isNewAppVersionAvailable = versionAvailable;
      },
    );
  }

  refreshApp(): void {
    window.location.reload();
  }

  protected readonly Application = Application;
}
