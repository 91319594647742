<mat-form-field style="width: 100%">
  <mat-label>Notizen</mat-label>
  <textarea
    style="min-height: 200px"
    matInput
    (change)="onSubmit()"
    class="form-control"
    [(ngModel)]="comment"
    (click)="startCommentWithCurrentDateTime(comment)"
  ></textarea>
</mat-form-field>
