<app-card>
  <app-card-head [pageTitle]="this.pageTitle">
    <app-filter-bar [filterSettings]="filterBarSettings"></app-filter-bar>
  </app-card-head>
  <app-card-body>
    <div class="row" cdkDropListGroup>
      <div class="col-sm-3" *ngFor="let list of partnerLeadDragDrop">
        <div class="card mb-2">
          <div class="card-header" style="height: 95px">
            <h5 class="">{{ list.title }}</h5>
            <span>({{ formatCurrency(list.summe) }})</span>
          </div>

          <div class="card-body">
            <ul
              style="min-height: 60px; border: 1px solid rgb(222, 226, 230)"
              class="list-group"
              cdkDropList
              #cols
              [cdkDropListData]="list.data"
              (cdkDropListDropped)="drop($event, list.status_fixed)"
            >
              <li
                class="list-group-item"
                *ngFor="let partnerLeadItem of list.data"
                cdkDrag
              >
                <ng-container *ngIf="partnerLeadItem.address">
                  <ng-container
                    *ngIf="partnerLeadItem.address.company_name != ''"
                  >
                    <b
                      class="text-primary"
                      style="cursor: pointer"
                      (click)="goToLeadDetail(partnerLeadItem)"
                    >
                      {{ partnerLeadItem.address.company_name }}</b
                    >
                  </ng-container>
                  <ng-container
                    *ngIf="partnerLeadItem.address.company_name == ''"
                  >
                    <b
                      class="text-primary"
                      style="cursor: pointer"
                      (click)="goToLeadDetail(partnerLeadItem)"
                    >
                      {{ partnerLeadItem.address.first_name }}
                      {{ partnerLeadItem.address.last_name }}</b
                    >
                  </ng-container>
                  <br />
                  {{ partnerLeadItem.address.postcode }}
                  {{ partnerLeadItem.address.city }} <br />

                  <app-partner-lead-offer-value
                    class="lead-list-input"
                    [partnerLead]="partnerLeadItem"
                    (partnerLeadOfferValue)="
                      updatePartnerLeadOfferValue($event)
                    "
                  ></app-partner-lead-offer-value>
                </ng-container>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </app-card-body>
</app-card>
