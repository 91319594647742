import { OptionlistsService } from './option-lists/optionlists.service';
import { NewSelectOption } from '../models/new-select-option';
import {
  BehaviorSubject,
  forkJoin,
  Observable,
  of,
  shareReplay,
  take,
} from 'rxjs';
import { Injectable } from '@angular/core';
import {
  catchError,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { Optionlist } from '../models/optionlist/optionlist';
import { SelectList } from '../models/select-list';
import { LeadType } from '../models/lead-type';
import { Option } from '../models/option';
import { LeadTypeService } from './lead-type/lead-type.service';
import { LeadField } from '../models/leads/lead-field';
import { OptionlistOption } from '../models/optionlist/optionlist-option';
import { LeadFieldService } from './leadfields/leadfields.service';
import { PartnersService } from './partners.service';
import { Partner } from '../models/partner';
import { DomainsService } from './domains/domains.service';
import { Domain } from '../models/domain';
import { AccountingAccountService } from './accounting_account.service';
import { AccountingAccount } from '../models/accounting_account';
import { PartnerUserService } from './partner/partner-user.service';
import { PartnerUser } from '../models/partners/partner-user';
import { NewSelectSelection } from '../models/new-select-selection';

@Injectable({
  providedIn: 'root',
})
export class SelectService {
  private leadTypeList$: Observable<NewSelectOption[]> | null = null;
  private leadDetailListRaw$: Observable<LeadField[]> | null = null;
  private optionlistsInitialized$ = new BehaviorSubject<boolean>(false);
  private partnerLeadDetailMap: Map<string, Observable<NewSelectOption[]>> =
    new Map();
  private partnerList$: Observable<NewSelectOption[]> | null = null;
  private domainList$: Observable<NewSelectOption[]> | null = null;
  private manualOptions: Map<SelectList, NewSelectOption[]> = new Map();
  private optionlists: Map<SelectList, NewSelectOption[]> = new Map();
  private accountingAccountList$: Observable<NewSelectOption[]> | null = null;
  private partnerUserList$: Observable<NewSelectOption[]> | null = null;
  private partnerUserSalesrunnerList$: Observable<NewSelectOption[]> | null =
    null;
  private singleOptionSelects: Map<SelectList, boolean> = new Map();

  constructor(
    private optionlistService: OptionlistsService,
    private leadTypeService: LeadTypeService,
    private leadFieldService: LeadFieldService,
    private partnerService: PartnersService,
    private domainService: DomainsService,
    private accountingAccountService: AccountingAccountService,
    private partnerUserService: PartnerUserService,
  ) {
    this.initializeManualOptions();
    this.initializeOptionlists();
  }

  public getSelectOptionsBySelectList(
    list: SelectList,
    filterConditions?: { [key: string]: NewSelectSelection },
  ): Observable<NewSelectOption[]> {
    return this.optionlistsInitialized$.pipe(
      filter((initialized) => initialized),
      take(1),
      switchMap(() => {
        const result$ = this.fetchOptionsForList(list, filterConditions);

        return result$.pipe(
          tap((options) => {
            this.setSingleOption(list, options.length === 1);
          }),
        );
      }),
    );
  }

  private fetchOptionsForList(
    list: SelectList,
    filterConditions?: { [key: string]: NewSelectSelection },
  ): Observable<NewSelectOption[]> {
    if (!list || list === SelectList.None) {
      return of([]);
    }

    const optionlist = this.optionlists.get(list);
    if (optionlist) {
      return of(optionlist);
    }

    const manualOptions = this.manualOptions.get(list);
    if (manualOptions) {
      return of(manualOptions);
    }

    switch (list) {
      case SelectList.LeadType:
        return this.getLeadTypeList();
      case SelectList.LeadDetail:
        return this.getLeadDetailList(filterConditions);
      case SelectList.Partner:
        return this.getPartnerList(filterConditions);
      case SelectList.Domain:
        return this.getDomainList();
      case SelectList.AccountingAccount:
        return this.getAccountingAccountList();
      case SelectList.PartnerUser:
        return this.getPartnerUserList();
      case SelectList.PartnerLeadDetail:
        return this.getPartnerLeadDetailList(filterConditions);
      case SelectList.PartnerUserSalesrunner:
        return this.getPartnerUserSalesrunnerList();
      default:
        return of([]);
    }
  }

  private getLeadTypeList(): Observable<NewSelectOption[]> {
    if (!this.leadTypeList$) {
      this.leadTypeList$ = this.leadTypeService.getList().pipe(
        map((leadTypes: LeadType[]) =>
          leadTypes.map((leadType) => {
            const newOption = new NewSelectOption();
            newOption.value = leadType.id;
            newOption.name = leadType.name;
            return newOption;
          }),
        ),
        shareReplay(1),
      );
    }
    return this.leadTypeList$;
  }

  private getLeadDetailList(filter?: {
    [key: string]: NewSelectSelection;
  }): Observable<NewSelectOption[]> {
    if (!this.leadDetailListRaw$) {
      this.leadDetailListRaw$ = this.leadFieldService
        .list({ filter: { type: 5 } })
        .pipe(shareReplay(1));
    }

    return this.leadDetailListRaw$.pipe(
      map((leadFields: LeadField[]) =>
        leadFields.reduce((acc, leadField) => {
          if (
            filter?.['lead_type_id'] &&
            !this.shouldIncludeLeadField(leadField, filter['lead_type_id'])
          ) {
            return acc;
          }

          const fieldOptions = leadField.optionlist?.options?.map(
            (option: OptionlistOption) => {
              return this.buildLeadDetailsSelectOption(option, leadField);
            },
          );
          return acc.concat(fieldOptions || []);
        }, [] as NewSelectOption[]),
      ),
    );
  }

  private shouldIncludeLeadField(
    leadField: LeadField,
    filterSelection: NewSelectSelection,
  ): boolean {
    if (
      !filterSelection ||
      !filterSelection.options ||
      filterSelection.options.length === 0
    ) {
      return true;
    }

    const filterIds = filterSelection.options.map((option) => option.value);
    const isIncluded = filterIds.includes(leadField.lead_type_id);

    return filterSelection.isNegative ? !isIncluded : isIncluded;
  }

  private buildLeadDetailsSelectOption(
    option: OptionlistOption,
    leadField: LeadField,
  ): NewSelectOption {
    const newOption = new NewSelectOption();
    newOption.value = {
      lead_type_id: leadField.lead_type_id,
      lead_type_name: leadField.lead_type?.name,
      lead_field_id: leadField.id,
      lead_field_name: leadField.name,
      option_id: option.option_id,
      option_name: option.name,
    };
    newOption.name = `${leadField.lead_type?.name}<br/>${leadField.name} | ${option.name}`;
    return newOption;
  }

  private getPartnerList(filter?: {
    [key: string]: NewSelectSelection;
  }): Observable<NewSelectOption[]> {
    if (!this.partnerList$ || filter?.['targetings.lead_type_id']) {
      const leadTypeFilter = filter ?? [];

      this.partnerList$ = this.partnerService
        .selectPartnerNames({
          pageSize: 100000,
          start: 0,
          page: 1,
          filter: leadTypeFilter,
        })
        .pipe(
          map((partners: Partner[]) => {
            return partners
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((partner) => ({
                value: partner.id,
                name: partner.name,
              }));
          }),
          shareReplay(1),
        );
    }
    return this.partnerList$;
  }

  private getDomainList(): Observable<NewSelectOption[]> {
    if (!this.domainList$) {
      this.domainList$ = this.domainService.getList().pipe(
        map((domains: Domain[]) =>
          domains.map((domain) => {
            const newOption = new NewSelectOption();
            newOption.value = domain.domain;
            newOption.name = domain.domain;
            return newOption;
          }),
        ),
        shareReplay(1),
      );
    }
    return this.domainList$;
  }

  private initializeOptionlists() {
    const optionlistConfig: [SelectList, string][] = [
      [SelectList.LeadStatus, 'lead_status'],
      [SelectList.PartnerLeadCancelReason, 'cancel_reason'],
      [SelectList.PartnerLeadStatus, 'partner_status'],
      [SelectList.TargetingStatus, 'targeting_status'],
      [SelectList.LeadResponse, 'lead_response'],
      [SelectList.PartnerResponse, 'partner_response'],
      [SelectList.PartnerBillStatus, 'bill_status'],
      [SelectList.PartnerAcquisitionStatus, 'partner_acquisition_status'],
      [SelectList.PartnerCancelReason, 'partner_cancel_reason'],
    ];

    const initializationObservables = optionlistConfig.map(
      ([selectList, keyword]) =>
        this.getSelectOptionsByOptionlistKeyword(keyword).pipe(
          tap((options) => this.optionlists.set(selectList, options)),
        ),
    );

    forkJoin(initializationObservables).subscribe(
      () => {
        this.optionlistsInitialized$.next(true);
      },
      (error) => {
        this.optionlistsInitialized$.next(false);
      },
    );
  }

  private initializeManualOptions() {
    const manualOptionsConfig: [SelectList, [any, string][]][] = [
      [
        SelectList.PartnerStatus,
        [
          [0, 'Inaktiv'],
          [1, 'Aktiv'],
        ],
      ],

      [
        SelectList.LeadCalledStatus,
        [
          [0, 'Alle'],
          [1, 'Ohne aktives Gespräch'],
          [2, 'Mit aktivem Gespräch'],
          [3, 'Ohne Anrufversuch'],
          [4, 'Mit Anrufversuch'],
          [5, 'Mindestens 1 eingehender Anruf'],
          [6, 'Erster Anruf ist eingehend'],
          [7, 'Erster Anruf ist ausgehend'],
        ],
      ],

      [
        SelectList.BillDirection,
        [
          ['incoming', 'Eingangsrechnung'],
          ['outgoing', 'Ausgangsrechnung'],
        ],
      ],
      [
        SelectList.BillStatus,
        [
          ['verarbeitet', 'verarbeitet'],
          ['verbucht', 'verbucht'],
          ['verbucht & bezahlt', 'verbucht & bezahlt'],
        ],
      ],
    ];

    manualOptionsConfig.forEach(([selectList, options]) => {
      this.setManualOptions(
        selectList,
        options.map(([value, name]) => this.createOption(value, name)),
      );
    });
  }

  private createOption(value: any, name: string): NewSelectOption {
    const option = new NewSelectOption();
    option.value = value;
    option.name = name;
    return option;
  }

  private setManualOptions(list: SelectList, options: NewSelectOption[]) {
    this.manualOptions.set(list, options);
  }

  private getManualOptions(list: SelectList): Observable<NewSelectOption[]> {
    const options = this.manualOptions.get(list);
    return options ? of(options) : of([]);
  }

  private getSelectOptionsByOptionlistKeyword(
    keyword: string,
  ): Observable<NewSelectOption[]> {
    return this.optionlistService.optionlistByKeyword(keyword).pipe(
      map((options) =>
        options.map((option: OptionlistOption) => {
          const newOption = new NewSelectOption();
          newOption.value = option.option_id;
          newOption.name = option.name;
          return newOption;
        }),
      ),
      shareReplay(1),
    );
  }

  private getAccountingAccountList(): Observable<NewSelectOption[]> {
    if (!this.accountingAccountList$) {
      this.accountingAccountList$ = this.accountingAccountService
        .getList()
        .pipe(
          map((accountingAccounts: AccountingAccount[]) =>
            accountingAccounts.map((account) => {
              const option = new NewSelectOption();
              option.value = account.account_number;
              option.name = account.account_name;
              return option;
            }),
          ),
          shareReplay(1),
        );
    }
    return this.accountingAccountList$;
  }

  private getPartnerUserList(): Observable<NewSelectOption[]> {
    if (!this.partnerUserList$) {
      this.partnerUserList$ = this.partnerUserService.getList().pipe(
        map((partnerUsers: PartnerUser[]) =>
          partnerUsers
            ? partnerUsers.map((user) => {
                const option = new NewSelectOption();
                option.value = user.id;
                option.name = `${user.first_name} ${user.last_name}`;
                return option;
              })
            : [],
        ),
        shareReplay(1),
      );
    }
    return this.partnerUserList$;
  }

  private getPartnerLeadDetailList(filter?: {
    [key: string]: NewSelectSelection;
  }): Observable<NewSelectOption[]> {
    if (
      !filter?.['lead_type_id'] ||
      filter['lead_type_id'].options.length === 0
    ) {
      return of([]); // Wenn die leadTypeId nicht gesetzt ist, dann soll nichts geladen werden
    }

    const leadTypeIds = filter['lead_type_id'].options.map(
      (option) => option.value,
    );
    const cacheKey = `partner_lead_detail_${leadTypeIds.join('_')}`;

    if (!this.partnerLeadDetailMap.has(cacheKey)) {
      const newList$ = this.leadFieldService
        .list({
          filter: { type: 5, lead_type_id: leadTypeIds },
        })
        .pipe(
          switchMap((leadFields: LeadField[]) =>
            of(leadFields).pipe(
              map((fields) =>
                fields.reduce((acc, leadField) => {
                  if (
                    !this.shouldIncludeLeadField(
                      leadField,
                      filter['lead_type_id'],
                    )
                  ) {
                    return acc;
                  }

                  const fieldOptions = leadField.optionlist?.options?.map(
                    (option: OptionlistOption) => {
                      return this.buildLeadDetailsSelectOption(
                        option,
                        leadField,
                      );
                    },
                  );
                  return acc.concat(fieldOptions || []);
                }, [] as NewSelectOption[]),
              ),
            ),
          ),
          shareReplay(1),
        );

      this.partnerLeadDetailMap.set(cacheKey, newList$);
    }

    return this.partnerLeadDetailMap.get(cacheKey)!;
  }

  // Todo wird das hier noch benötigt? Verstehe nicht wofür das gut ist
  private getPartnerUserSalesrunnerList(): Observable<NewSelectOption[]> {
    if (!this.partnerUserSalesrunnerList$) {
      this.partnerUserSalesrunnerList$ = this.partnerUserService
        .getPartnerUsersForAdmin()
        .pipe(
          map((partnerUsers: PartnerUser[]) =>
            partnerUsers
              ? partnerUsers.map((user) => {
                  const option = new NewSelectOption();
                  option.value = user.id;
                  option.name = `${user.first_name} ${user.last_name}`;
                  return option;
                })
              : [],
          ),
          catchError((error) => {
            console.error('Error fetching partner user salesrunners:', error);
            return of([]);
          }),
          shareReplay(1),
        );
    }
    return this.partnerUserSalesrunnerList$;
  }

  public setSingleOption(selectList: SelectList, isSingle: boolean) {
    this.singleOptionSelects.set(selectList, isSingle);
  }

  public hasSingleOption(selectList: SelectList): boolean {
    return this.singleOptionSelects.get(selectList) || false;
  }
}
