import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DataTablesParameters } from '../../interfaces/optionen.interface';
import { map } from 'rxjs/operators';
import { HttpHandlerService } from '../http-handler.service';
import { LeadType } from '../../models/lead-type';
import { EnvironmentService } from '../environment.service';

@Injectable({
  providedIn: 'root',
})
export class LeadTypeService {
  endpoint: string = 'lead_types/';
  endpointList: string = 'lead_types_list/';
  endpointPartnerLeadType: string = 'partner_lead_types/';

  constructor(
    private httpHandler: HttpHandlerService,
    protected http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  datatable(dataTablesParams: DataTablesParameters): Observable<any> {
    return this.httpHandler.datatable(this.endpoint, dataTablesParams).pipe(
      map((response) => {
        return {
          recordsFiltered: response.recordsFiltered,
          recordsTotal: response.recordsTotal,
          data: response.data.map((item: LeadType) => new LeadType(item)),
        };
      }),
    );
  }

  list(): Observable<any> {
    return this.httpHandler
      .list(this.endpoint)
      .pipe(map((response) => response.data));
  }

  getList(): Observable<any> {
    return this.httpHandler
      .list(this.endpointList)
      .pipe(map((response) => response.data));
  }

  show(id: number): Observable<any> {
    return this.httpHandler.show(this.endpoint, id);
  }

  create(leadType: LeadType): Observable<any> {
    return this.httpHandler.create(this.endpoint, leadType);
  }

  update(leadType: LeadType): Observable<any> {
    return this.httpHandler.update(this.endpoint, leadType);
  }

  delete(id: number): Observable<any> {
    return this.httpHandler.delete(this.endpoint, id);
  }

  emailPreview(data: any): Observable<any> {
    // @ts-ignore
    let dataRequest = {
      preview: {
        email_template_id: data.email_template_id,
        sender_email: data.sender_email,
        sender_name: data.sender_name,
        sms_device_id: data.sms_device_id,
        subject: data.subject,
        body: data.body,
      },
      lead_type_id: data.lead_type_id,
    };
    return this.http.post(
      this.environmentService.apiUrl + 'email_preview/',
      dataRequest,
      {
        responseType: 'json',
      },
    );
  }

  /*for salesrunner api*/
  listPartnerLeadType(): Observable<any> {
    return this.httpHandler
      .list(this.endpointPartnerLeadType)
      .pipe(map((response) => response.data));
  }
}
