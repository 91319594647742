import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { TableComponent } from '@lib/components/table/table.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateHelper } from '@lib/helpers/date-helper';
import {
  combineLatest,
  of,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { SelectList } from '@lib/models/select-list';
import { PartnerLead } from '@lib/models/partner-lead';
import { FilterSetting } from '@lib/models/filter-setting';

import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { PartnerCancelReason, PartnerLeadStatus } from '@lib/enum-collection';
import { LeadProjectFinishedModalComponent } from './lead-project-finished-modal/lead-project-finished-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { OptionlistOption } from '@lib/models/optionlist/optionlist-option';
import { AuthService } from '@lib/services/auth.service';
import { PartnerUser } from '@lib/models/partners/partner-user';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { SelectService } from '@lib/services/select.service';
import { NewSelectSelection } from '@lib/models/new-select-selection';
import { NewFilterService } from '@lib/services/filter/new-filter.service';

@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.scss'],
})
export class LeadListComponent implements OnInit, OnDestroy {
  protected readonly SelectList = SelectList;
  @ViewChild(TableComponent) tableComponent!: TableComponent;
  public filterBarSettings: FilterSetting[] = [];
  public filterSubscription: Subscription = new Subscription();
  partnerScore: number = 0;
  displayOnlyReminders: boolean = false;
  partnerUser$ = this.authService.partnerUser$;
  partnerLeads: PartnerLead[] = [];
  missingBillsInitialValue: boolean = false;
  invoiceAndPerformanceData: boolean = false;

  filter = {
    status: null,
    lead_type_id: null,
    lead_details: null,
    partner_user_id: null,
    lead_response: null,
  };

  private destroy$ = new Subject<void>();

  constructor(
    private partnerLeadService: PartnerLeadService,
    private snackbarService: SnackBarService,
    private filterService: NewFilterService,
    private authService: AuthService,
    public dialog: MatDialog,
    public selectService: SelectService,
    private router: Router,
  ) {}

  ngOnInit() {
    this.hasMissingBills();
    this.initFilter();

    this.partnerLeadService
      .getPartnerLeadScoreByAuthenticatedPartnerUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((leadScore) => {
        this.partnerScore = leadScore;
      });
  }

  hasMissingBills() {
    this.partnerLeadService
      .hasMissingBills()
      .pipe(takeUntil(this.destroy$))
      .subscribe((response) => {
        this.missingBillsInitialValue = response;
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  initFilter(disabledFilter = false) {
    this.filterSubscription = this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.setFilter(filter);
      });

    this.filterBarSettings = [
      new FilterSetting({
        filter: SelectList.LeadResponse,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Lead Rückmeldung',
      }),
      new FilterSetting({
        filter: SelectList.PartnerLeadStatus,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Lead Status',
      }),
      new FilterSetting({
        filter: SelectList.PartnerUser,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Benutzer',
      }),
      new FilterSetting({
        filter: SelectList.LeadType,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Lead-Art',
      }),
      new FilterSetting({
        filter: SelectList.PartnerLeadDetail,
        multiSelect: true,
        disabled: disabledFilter,
        placeholder: 'Lead Details',
      }),
    ];

    this.filterService.getFilter();
  }

  setFilter(filter: any) {
    const filterData = filter?.filter ?? filter;

    this.filter = {
      ...this.filter,
      status: filterData?.[SelectList.PartnerLeadStatus],
      lead_type_id: filterData?.[SelectList.LeadType],
      lead_details: filterData?.[SelectList.PartnerLeadDetail],
      partner_user_id: filterData?.[SelectList.PartnerUser],
      lead_response: filterData?.[SelectList.LeadResponse],
    };
  }

  setLeadStatus(
    newStatusOption: any,
    partnerLead: PartnerLead,
    currentStatus: OptionlistOption,
  ) {
    const newStatusOptionId = newStatusOption.value;
    const isProjectFinished =
      newStatusOptionId ===
      PartnerLeadStatus['Auftrag erhalten & Projekt beendet'];

    if (isProjectFinished) {
      this.openProjectFinishedDialog(
        partnerLead,
        newStatusOptionId,
        currentStatus.option_id,
      );
    } else {
      this.changeLeadStatus(newStatusOptionId, partnerLead);
    }
  }

  openProjectFinishedDialog(
    partnerLead: PartnerLead,
    newStatusId: number,
    fallbackStatusId: number,
  ) {
    const dialogRef = this.dialog.open(LeadProjectFinishedModalComponent, {
      width: '500px',
      data: { partnerLead },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerAccepted: boolean) => {
        const statusId = partnerAccepted ? newStatusId : fallbackStatusId;
        this.changeLeadStatus(statusId, partnerLead);
      });
  }

  changeLeadStatus(newStatusOptionId: number, partnerLead: PartnerLead) {
    this.partnerLeadService
      .showPartnerLead(partnerLead.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerLeadResponse) => {
        partnerLeadResponse.status = newStatusOptionId;
        this.updatePartnerLead(partnerLeadResponse);
      });
  }

  setLeadCancelReason(cancelReasonOption: any, partnerLead: PartnerLead) {
    this.partnerLeadService
      .showPartnerLead(partnerLead.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerLeadResponse) => {
        partnerLeadResponse.cancel_reason_id = cancelReasonOption.value;

        this.updatePartnerLead(partnerLeadResponse);
      });
  }

  updatePartnerLead(partnerLead: PartnerLead) {
    this.partnerLeadService
      .update(partnerLead)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.reloadTable();
          this.hasMissingBills();
          this.snackbarService.openSnackBar(
            'Daten erfolgreich aktualisiert.',
            'success',
          );
        },
        () => {
          this.snackbarService.openSnackBar(
            'Daten konnten nicht aktualisiert werden.',
            'warn',
          );
        },
      );
  }

  reloadTable() {
    this.tableComponent.reloadData();
  }

  onCancelReasonChange(partnerLead: PartnerLead) {
    this.partnerLeadService
      .showPartnerLead(partnerLead.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerLeadResponse) => {
        partnerLeadResponse.cancel_reason = partnerLead.cancel_reason;

        this.updatePartnerLead(partnerLeadResponse);
      });
  }

  dateChangeEvent(
    event: MatDatepickerInputEvent<Date>,
    partnerLead: PartnerLead,
  ) {
    const inputElement = event.targetElement as HTMLInputElement;

    let formattedDate = DateHelper.formatUserInputDate(inputElement.value);

    this.partnerLeadService
      .showPartnerLead(partnerLead.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerLeadResponse) => {
        partnerLeadResponse.follow_up_at =
          DateHelper.prepareForDatabase(formattedDate);

        this.updatePartnerLead(partnerLeadResponse);
      });
  }

  captureInvoice(partnerLead: PartnerLead) {
    this.router.navigate(['/partner_bills/create/' + partnerLead.id]);
  }

  getMissingLastBill(partnerLead: PartnerLead): string {
    // Sicherstellen, dass `bills` vorhanden und ein Array ist
    if (!partnerLead.bills || partnerLead.bills.length === 0) {
      return '<span class="badge bg-danger fs-6 fw-normal">Bitte Rechnung eintragen<br />Noch keine Rechnung vorhanden!</span>';
    }

    // Letzte Rechnung im Array finden, basierend auf dem Datum
    const lastBill = partnerLead.bills.reduce(
      (latest: Date, bill: { bill_date: string }) => {
        const billDate = new Date(bill.bill_date);
        return billDate > latest ? billDate : latest;
      },
      new Date(0),
    );

    // Prüfen, ob das Datum der letzten Rechnung vor dem ersten Tag des letzten Monats liegt
    if (lastBill < DateHelper.getFirstDayOfLastMonth()) {
      return (
        '<span class="badge bg-danger btn-sm fs-7 fw-normal">Bitte Rechnung eintragen<br />Letzte Rechnung: ' +
        DateHelper.getFormattedDate(lastBill) +
        '</span>'
      );
    } else {
      return 'Letzte Rechnung: ' + DateHelper.getFormattedDate(lastBill);
    }
  }

  getHasMissingLastBill(): boolean {
    // Durch das Array der PartnerLeads gehen
    for (const partnerLead of this.partnerLeads) {
      // Prüfen, ob der Status 'Auftrag erhalten' ist
      if (
        partnerLead.status.option_id === PartnerLeadStatus['Auftrag erhalten']
      ) {
        // Sicherstellen, dass Rechnungen vorhanden sind
        if (partnerLead.bills && partnerLead.bills.length > 0) {
          // Letzte Rechnung im Array finden, basierend auf dem Datum
          const lastBillDate = partnerLead.bills.reduce(
            (latest: Date, bill: { bill_date: string }) => {
              const billDate = new Date(bill.bill_date);
              return billDate > latest ? billDate : latest;
            },
            new Date(0),
          );

          // Prüfen, ob das Datum der letzten Rechnung vor dem ersten Tag des letzten Monats liegt
          if (lastBillDate < DateHelper.getFirstDayOfLastMonth()) {
            return true;
          }
        } else {
          return true;
        }
      }
    }

    // Keine fehlenden Rechnungen gefunden
    return false;
  }

  onDisplayedDataChanged(partnerLeads: PartnerLead[]) {
    this.partnerLeads = partnerLeads;
  }

  fetchDataAndSaveExcel() {
    this.partnerLeadService
      .export({ filter: this.filter, export: true })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: HttpResponse<Blob | null>) => {
          if (response.body) {
            this.saveExcelFile(response.body as Blob);
          } else {
            this.snackbarService.openSnackBar(
              'Oops, etwas schied gelaufen',
              'danger',
            );
          }
        },
        (error) => {
          console.error('Error fetching Excel data', error);
        },
      );
  }

  saveExcelFile(blob: Blob) {
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = 'export.xlsx';
    link.click();
  }

  onDisplayOnlyRemindersClick() {
    if (this.displayOnlyReminders) {
      this.selectService
        .getSelectOptionsBySelectList(SelectList.PartnerLeadStatus)
        .subscribe((options) => {
          const wiedervorlageOption = options.find(
            (option) => option.value === 3,
          );

          if (wiedervorlageOption) {
            const selection = new NewSelectSelection();
            selection.options = [wiedervorlageOption];
            selection.isNegative = false;

            this.filterService.setFilter({
              filter: {
                [SelectList.PartnerLeadStatus]: selection,
              },
            });
          } else {
            console.warn('No "Wiedervorlage" option found with value 3');
          }
        });
    } else {
      const emptySelection = new NewSelectSelection();
      this.filterService.setFilter({
        filter: {
          [SelectList.PartnerLeadStatus]: emptySelection,
        },
      });
    }

    this.tableComponent.applySort('follow_up_at', 'asc');
  }

  protected readonly PartnerLeadStatus = PartnerLeadStatus;
  protected readonly PartnerCancelReason = PartnerCancelReason;
}
