import { LeadType } from '../lead-type';
import { Address } from '../address';
import { Status } from '../status';
import { PartnerLead } from '../partner-lead';
import { LeadFeedBacks } from './lead-feed-backs';
import { Call } from '../call';
import { LeadBackend } from '../lead-backend';
import { User } from '../user';
import { ApiEntry } from '../api-entry/api-entry';

export class Lead {
  id: number = 0;
  address_id: number | null = null;
  address = new Address({});
  lead_type_id: number = 0;
  calls_count: number = 0;
  lead_type = new LeadType({});
  number: string = '';
  lead_number: string = '';
  offer_value: number = 0;
  provision_per_lead_in: number = 0;
  status: number = 1;
  status_option = new Status({});
  calls: Call[] = [];
  external_competitors: any[] = [];
  last_call = new Call({});
  //cancel_reason: number = 0;
  request_at: string = '';
  sold_at: string = '';
  last_call_event_at: string = '';
  matomo_user_id: string = '';
  domain: string = '';
  email_notification_for_lead: number = 0;
  form_id: number = 0;
  form_name: string = '';
  referral_summary: string = '';
  refferer_type: string = '';
  campaign_source: string = '';
  campaign_name: string = '';
  adgroup_name: string = '';
  landing_page: string = '';
  gclid: string = '';
  msclkid: string = '';
  lead_response: number | null = null;
  lead_response_at: string | null = null;
  lead_response_success_partner_lead_id: number = 0;
  matomo_forward_sync_date: string | null = null;
  matomo_order_sync_date: string | null = null;
  created_at: string | null = null;
  updated_at: string | null = null;
  auth_hash: string = '';
  sales_forecast: number | null = 0;
  //lead_last_offer_response_at: string = '';
  sold_by_user_id: number = 0;
  blocked_by_user_id: number = 0;
  blocked_last_heartbeat_at: string | null = null;
  score_median_max: number = 0;
  need_period_start: string | null = null;
  need_period_end: string | null = null;
  is_blocked: boolean = false;
  blocking_user: any;
  partner_leads_count: number = 0;
  accepted_partner_leads_count: number = 0;
  partner_leads_status_contact_count: number = 0;
  partner_leads_status_lost_count: number = 0;
  partner_leads_status_offer_count: number = 0;
  partner_leads_status_order_count: number = 0;
  partner_bills_sum: number = 0;
  lead_backend: LeadBackend = new LeadBackend({});
  lead_details: any = [];
  addresses: any = [];
  seller: any = new User({});
  nps: any = {};
  partner_leads: PartnerLead[] = [];
  lead_feedbacks: any = new LeadFeedBacks({});
  api_entry: ApiEntry = new ApiEntry({});
  dsgvo_deleted_user_id: number | null = null;
  dsgvo_deleted_at: string | null = null;
  nps_score_at: string | null = null;
  latest_created_at: string = '';
  lead_response_success_partner_lead: PartnerLead = new PartnerLead({});

  constructor(lead: Lead | {}) {
    Object.assign(this, lead);

    if (this.lead_backend == null) {
      Object.assign(this, {
        ...lead,
        lead_backend: new LeadBackend(this.lead_backend || {}),
      });
    }
  }
}
