export enum BillStatus {
  'Erfasst' = 1,
  'Abgerechnet' = 2,
  'Storniert' = 3,
}

export enum PartnerLeadStatus {
  'Neu' = 1,
  'Kontakt hergestellt' = 2,
  'Angebot versendet & Wiedervorlage' = 3,
  'Verloren' = 4,
  'Auftrag erhalten' = 5,
  'Kundenseitige Auftragsmeldung' = 6,
  'Warte auf Annahme' = 7,
  'Lead abgelehnt' = 8,
  'Auftrag erhalten & Projekt beendet' = 9,
}

export enum PartnerCancelReason {
  'Interessent hat Projekt abgesagt / Kein Bedarf mehr' = 1,
  'Wettbewerber beauftragt' = 2,
  'Nicht erreicht / Keine Kommunikation' = 3,
  'Aktuell fehlende Produktverfuegbarkeit' = 4,
  'Generell kein passendes Produkt im Angebot' = 5,
  'Anfrage lag bereits direkt vor' = 6,
  'Fuer mich wirtschaftlich nicht attraktiv' = 7,
  'Technisch nicht realisierbar' = 8,
  'Planer wollte LV erstellen / Grober Richtpreis' = 9,
  'Grund unbekannt' = 10,
  'Sonstiges (manuelle Eingabe)' = 11,
}

export enum ModalFormFieldType {
  'text' = 'text',
  'checkbox' = 'checkbox',
  'textarea' = 'textarea',
  'radio' = 'radio',
  'date' = 'date',
  'select' = 'select',
}

export enum PartnerUserCostType {
  'Lead' = 'lead',
  // 'Lead' = 'sale',
  'Sale' = 'sale',
  // 'Sale' = 'lead',
}

export enum PartnerAcquisitionStatus {
  'Akquise' = 1,
  'Aktiver Partner' = 2,
  'Verloren' = 3,
}

export enum Application {
  'Leadmanager' = 'Leadmanager',
  'Salesrunner' = 'Salesrunner',
}
