export class DateHelper {
  static formatDate(dateString: string): string {
    const myDate = new Date(dateString);
    const day = myDate.getDate().toString().padStart(2, '0');
    const month = (myDate.getMonth() + 1).toString().padStart(2, '0');
    const year = myDate.getFullYear().toString();

    return `${year}-${month}-${day}`;
  }

  static formatDateObj(dateString: Date): string {
    const myDate = new Date(dateString);
    const day = myDate.getDate().toString().padStart(2, '0');
    const month = (myDate.getMonth() + 1).toString().padStart(2, '0');
    const year = myDate.getFullYear().toString();

    return `${year}-${month}-${day}`;
  }

  static getStartOfMonth(): string {
    const today = new Date(); // Get the current date
    const berlinTimezoneOffset = 60 * 60 * 1000; // Berlin time zone offset in milliseconds (UTC+1)
    // Set date_range_start to the first day of the current month
    const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    startOfMonth.setUTCHours(23, 59, 59, 999); // Set time to end of the day in UTC

    const startOfMonthBerlin = new Date(
      startOfMonth.getTime() + berlinTimezoneOffset,
    ); // Adjust to Berlin time zone
    const startOfMonthISO = startOfMonthBerlin.toISOString();
    return startOfMonthISO;
  }

  static getEndOfMonth(): string {
    const today = new Date(); // Get the current date
    const berlinTimezoneOffset = 60 * 60 * 1000; // Berlin time zone offset in milliseconds (UTC+1)
    const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    endOfMonth.setUTCHours(23, 59, 59, 999); // Set time to end of the day in UTC
    const endOfMonthBerlin = new Date(
      endOfMonth.getTime() + berlinTimezoneOffset,
    ); // Adjust to Berlin time zone
    const endOfMonthISO = endOfMonthBerlin.toISOString();
    return endOfMonthISO;
  }

  static getOneYearAgo(): string {
    const today = new Date(); // Get the current date
    today.setFullYear(today.getFullYear() - 1);
    return today.toISOString();
  }

  /**
   * Diese Funktion formatiert und validiert ein Eingabedatum, das als String bereitgestellt wird.
   * Die Funktion entfernt nicht-numerische Zeichen, außer Punkten, und überprüft das Format des Datums.
   * Sie stellt sicher, dass der Monat gültig ist und korrigiert das Datum, falls nötig.
   * Zusätzlich wird das Jahr überprüft, ob es im gültigen Bereich liegt (zwischen dem aktuellen Jahr und 2050).
   * Das zurückgegebene Datum ist im Format dd.mm.yyyy.
   */
  static formatUserInputDate(dateString: string): string {
    // Entferne alle Zeichen außer Zahlen und Punkten
    dateString = dateString.replace(/[^\d.]/g, '');

    // Aufteilung in Tag, Monat, Jahr
    let parts = dateString.split('.');
    if (parts.length < 3) {
      if (/^\d{6}$/.test(dateString)) {
        // Wenn das Datum als 'ddmmyy' eingegeben wurde
        parts = [
          dateString.slice(0, 2), // Tag
          dateString.slice(2, 4), // Monat
          '20' + dateString.slice(4, 6), // Jahr (Annahme: 2000er)
        ];
      } else if (/^\d{8}$/.test(dateString)) {
        // Wenn das Datum als 'ddmmyyyy' eingegeben wurde
        parts = [
          dateString.slice(0, 2), // Tag
          dateString.slice(2, 4), // Monat
          dateString.slice(4, 8), // Jahr
        ];
      }
    }

    // Validierung und Korrektur des Monats und Jahres
    let day = parseInt(parts[0]);
    let month = parseInt(parts[1]);
    let year = parseInt(parts[2]);

    if (month > 12) {
      // Tausche Tag und Monat, falls der Monat > 12
      [day, month] = [month, day];
    }

    // Wenn das Jahr nur zwei Ziffern hat, füge das Jahrhundert hinzu
    if (year < 100) {
      year = year < 50 ? 2000 + year : year;
    }

    const currentYear = new Date().getFullYear();
    if (year < currentYear || year > 2050) {
      // Wenn das Datum in der Vergangenheit oder nach 2050 liegt, setze das Jahr auf das aktuelle Jahr
      year = currentYear;
    }

    // Zurückgeben des formatierten Datums
    return `${day < 10 ? '0' + day : day}.${
      month < 10 ? '0' + month : month
    }.${year}`;
  }

  static prepareForDatabase(value: string): string | null {
    // Zuerst überprüfen, ob der Wert dem erwarteten Format entspricht
    const regex = /^(\d{2})\.(\d{2})\.(\d{4})$/;
    const matches = value.match(regex);

    if (matches && matches.length === 4) {
      // Extrahiere Tag, Monat und Jahr
      const [, day, month, year] = matches;

      // Format umwandeln und zurückgeben
      return `${year}-${month}-${day}`;
    } else {
      // Bei ungültigem Format, null zurückgeben oder eine Fehlerbehandlung durchführen
      return null;
    }
  }

  static getFormattedDateTime(date: Date): string {
    const dateString = `${date.getDate().toString().padStart(2, '0')}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}.${date.getFullYear()}`;

    const timeString = date.toTimeString().split(' ')[0];

    return `${dateString} ${timeString} Uhr`;
  }

  static getFormattedDate(date: Date): string {
    const dateString = `${date.getDate().toString().padStart(2, '0')}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}.${date.getFullYear()}`;

    return `${dateString}`;
  }

  static getFirstDayOfLastMonth(): Date {
    const firstDayOfLastMonth = new Date();
    firstDayOfLastMonth.setMonth(firstDayOfLastMonth.getMonth() - 1);
    firstDayOfLastMonth.setDate(1);
    firstDayOfLastMonth.setHours(0, 0, 0, 0);

    return firstDayOfLastMonth;
  }

  static geLastDayOfLastMonth(): Date {
    const date = new Date();
    date.setDate(1); // Setze den Tag auf den ersten des Monats

    // Gehe zum letzten Tag des letzten Monats, indem wir einen Tag vor dem ersten dieses Monats gehen
    date.setDate(0);

    return date;
  }

  static getDateRangeAllTime(): { start: string; end: string } {
    return {
      start: '2020-01-01',
      end: DateHelper.formatDate(new Date().toString()),
    };
  }
}
