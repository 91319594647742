import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartnerBillService } from '../../../services/partner-bill.service';
import { PartnerLead } from '@lib/models/partner-lead';
import { PartnerBill } from '@lib/models/partners/partner-bill';
import { OptionlistOption } from '@lib/models/optionlist/optionlist-option';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { FileUploadService } from '@lib/services/file-upload/file-upload.service';
import { OptionlistsService } from '@lib/services/option-lists/optionlists.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateHelper } from '@lib/helpers/date-helper';
import { BillStatus } from '@lib/enum-collection';
import { PartnerBillsComponent } from '../partner-bills/partner-bills.component';
import { FileUploadComponent } from '@lib/components/file-upload/file-upload.component';
import { FileUpload } from '@lib/models/fileUpload';
import { ValidationPatterns } from '@lib/validations/validation-pattern';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PriceNumberFormatHelper } from '@lib/helpers/price-number-format-helper';

@Component({
  selector: 'app-partner-bill-form',
  templateUrl: './partner-bill-form.component.html',
  styleUrls: ['./partner-bill-form.component.scss'],
})
export class PartnerBillFormComponent implements OnInit, OnDestroy {
  @ViewChild(PartnerBillsComponent)
  partnerBillsComponent!: PartnerBillsComponent;

  @ViewChild(FileUploadComponent)
  fileUploadComponent!: FileUploadComponent;

  @Output() filesReady = new EventEmitter<any>();

  partnerBillForm: FormGroup = new FormGroup({});
  partnerLead: PartnerLead = new PartnerLead({});
  partnerBill: PartnerBill = new PartnerBill({});
  billStatusOptionlist: OptionlistOption[] = [];
  uploadedDocuments: FileUpload[] = [];

  protected readonly BillStatus = BillStatus;

  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private partnerBillService: PartnerBillService,
    private partnerLeadService: PartnerLeadService,
    private snackbarService: SnackBarService,
    private optionListService: OptionlistsService,
    private fileUploadService: FileUploadService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit() {
    this.initForm();
    this.loadData();
  }

  get billPositions(): FormArray {
    return this.partnerBillForm.get('billPositions') as FormArray;
  }

  private initForm() {
    this.partnerBillForm = this.formBuilder.group({
      billStatus: ['', Validators.required],
      billDate: ['', Validators.required],
      billSum: ['', Validators.required],
      billNumber: [],
      billComment: [],
      billsUpload: [],
      billPositions: this.formBuilder.array([]),
    });
  }

  loadData() {
    this.route.params
      .pipe(takeUntil(this.destroy$))
      .subscribe((routeParams) => {
        this.partnerLead.id = routeParams['partnerLeadId'];

        this.optionListService
          .optionlistByKeyword('bill_status')
          .pipe(takeUntil(this.destroy$))
          .subscribe((data: OptionlistOption[]) => {
            this.billStatusOptionlist = data;
          });

        this.partnerLeadService
          .showPartnerLead(routeParams['partnerLeadId'])
          .pipe(takeUntil(this.destroy$))
          .subscribe((data) => {
            this.partnerLead = data;

            this.validateRequiredInvoice();

            if (routeParams['partnerBillId']) {
              this.getPartnerBills(routeParams['partnerBillId']);
            } else {
              this.initBillPositions();
            }
          });
      });
  }

  validateRequiredInvoice() {
    if (this.partnerLead?.partner?.required_invoice) {
      this.partnerBillForm
        .get('billsUpload')
        ?.setValidators(Validators.required);
    } else {
      this.partnerBillForm.get('billsUpload')?.clearValidators();
    }

    this.partnerBillForm.get('billsUpload')?.updateValueAndValidity();
  }

  private patchValues() {
    // Lösche alle/alte Positionen
    this.billPositions.clear();

    this.filesReady.emit(this.partnerBill.documents);

    // wird benötigt, um später bereits hochgeladene Dateien vom speichern auszuschließen
    this.uploadedDocuments = this.partnerBill.documents;

    this.partnerBillForm.patchValue({
      billStatus: this.partnerBill?.bill_status || BillStatus.Erfasst,
      billDate:
        this.partnerBill?.bill_date || DateHelper.geLastDayOfLastMonth(),
      billSum: PriceNumberFormatHelper.replaceDotWithComma(
        this.partnerBill?.bill_sum,
      ),
      billNumber: this.partnerBill?.bill_number,
      billComment: this.partnerBill?.comment,
      billsUpload: this.partnerBill?.documents,
    });

    // Setze die Positionen
    for (const partnerBillPosition of this.partnerBill.partner_bill_positions) {
      const position = this.formBuilder.group({
        id: [partnerBillPosition.id],
        name: [
          {
            value: partnerBillPosition.name ? partnerBillPosition.name : '',
            disabled: true,
          },
          Validators.required,
        ],
        commissionRate: [
          {
            value: partnerBillPosition.commission_rate
              ? PriceNumberFormatHelper.formatInputNetPrice(
                  partnerBillPosition.commission_rate,
                )
              : '',
            disabled: true,
          },
          Validators.required,
        ],
        amount: [
          {
            value: partnerBillPosition.amount
              ? PriceNumberFormatHelper.formatInputNetPrice(
                  partnerBillPosition.amount,
                )
              : '',
            disabled: partnerBillPosition.is_standard === 1,
          },
          [Validators.required],
        ],
      });
      this.billPositions.push(position);
    }

    // Abgerechnet Rechnungen dürfen nicht mehr bearbeitet werden
    if (this.partnerBill.bill_status === BillStatus.Abgerechnet) {
      this.partnerBillForm.disable();
    }

    this.onBillStatusChange();
    this.validateBillDate();
  }

  onBillStatusChange() {
    this.partnerBillForm
      .get('billStatus')
      ?.valueChanges.pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.validateBillDate();
      });
  }

  validateBillDate() {
    const billDateControl = this.partnerBillForm.get('billDate');

    if (this.partnerBillForm.get('billStatus')?.value !== 1) {
      billDateControl?.disable();
    } else {
      billDateControl?.enable();
    }

    billDateControl?.updateValueAndValidity();
  }

  initBillPositions() {
    this.partnerBillService
      .initBillPositions(this.partnerLead.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any) => {
        this.partnerBill.partner_bill_positions = data;

        this.partnerBill.partner_lead_id = this.partnerLead.id;
        this.patchValues();
      });
  }

  getPartnerBills(partnerBillId: number) {
    this.partnerBillService
      .show(partnerBillId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: PartnerBill) => {
        this.partnerBill = new PartnerBill(data);

        this.partnerBill.partner_lead_id = this.partnerLead.id;
        this.patchValues();
      });
  }

  onSubmit(createNewBill = false) {
    this.onAmountChange();
    if (this.partnerBillForm.valid) {
      // Berechne die Nettorechnungssumme vor dem speichern immer einmal neu. Sonst kann es zu Rundungsfehlern kommen.
      this.onBillSumChange();

      this.setPartnerBillToSave();

      if (this.partnerBill.id) {
        if (this.partnerBill.bill_status === BillStatus.Erfasst) {
          this.partnerBillService
            .update(this.partnerBill)
            .pipe(takeUntil(this.destroy$))
            .subscribe((partnerBill) => {
              this.saveSuccessful(partnerBill, createNewBill);
            });
        }
      } else {
        this.partnerBillService
          .create(this.partnerBill)
          .pipe(takeUntil(this.destroy$))
          .subscribe((partnerBill: PartnerBill) => {
            this.saveSuccessful(partnerBill, createNewBill);
          });
      }
    }
  }

  setPartnerBillToSave() {
    const billDate = DateHelper.formatDate(
      new Date(this.partnerBillForm.get('billDate')?.value).toString(),
    );

    this.partnerBill.bill_status =
      this.partnerBillForm.get('billStatus')?.value;
    this.partnerBill.bill_date = billDate;
    this.partnerBill.bill_sum =
      PriceNumberFormatHelper.formatForDB(
        this.partnerBillForm.get('billSum')?.value,
      ) || 0;
    this.partnerBill.bill_number =
      this.partnerBillForm.get('billNumber')?.value;
    this.partnerBill.comment = this.partnerBillForm.get('billComment')?.value;
    this.partnerBill.documents = this.partnerBillForm.get('billsUpload')?.value;

    this.updateBillPositions();
  }

  updateBillPositions(): void {
    const updatedPositions = this.getBillPositions();

    // Aktualisiere bestehende Positionen mit neuen Werten
    this.partnerBill.partner_bill_positions.forEach((existingPosition) => {
      const updatedPosition = updatedPositions.find(
        (up) => up.name === existingPosition.name,
      );

      if (updatedPosition) {
        existingPosition.name = updatedPosition.name;
        existingPosition.commission_rate = updatedPosition.commission_rate;
        existingPosition.amount = updatedPosition.amount;
      }
    });
  }

  getBillPositions(): any[] {
    return this.billPositions.controls.map((control) => ({
      id: control.get('id')?.value,
      name: control.get('name')?.value,
      commission_rate: control.get('commissionRate')?.value,
      amount:
        PriceNumberFormatHelper.formatForDB(control.get('amount')?.value) || 0,
    }));
  }

  saveSuccessful(partnerBill: PartnerBill, createNewBill: boolean) {
    this.snackbarService.openSnackBar(
      'Daten erfolgreich gespeichert',
      'success',
    );

    this.partnerBillsComponent?.reloadTableData();
    this.uploadFile(partnerBill, createNewBill);
    this.navigateToBill(partnerBill, createNewBill);

    this.partnerBill = new PartnerBill({});
    this.initForm();
  }

  uploadFile(partnerBill: PartnerBill, createNewBill: boolean = false) {
    let newDocuments = this.partnerBillForm.get('billsUpload')?.value;

    // Filtere bereits hochgeladene Dateien heraus
    newDocuments = newDocuments?.filter(
      (documents: FileUpload) => !this.uploadedDocuments.includes(documents),
    );

    if (newDocuments && newDocuments.length > 0) {
      for (const file of newDocuments) {
        this.fileUploadService
          .upload(file, partnerBill.id, 'partner_bill')
          .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.fileUploadComponent.clearAllFiles();
            this.navigateToBill(partnerBill, createNewBill);
            this.loadData();
          });
      }
    } else {
      this.navigateToBill(partnerBill, createNewBill);
      this.loadData();
    }
  }

  navigateToBill(partnerBill: PartnerBill, createNewBill: boolean = false) {
    if (createNewBill) {
      this.router.navigate([
        '/partner_bills',
        'create',
        partnerBill.partner_lead_id,
      ]);
    } else {
      this.router.navigate([
        '/partner_bills',
        'edit',
        partnerBill.partner_lead_id,
        partnerBill.id,
      ]);
    }
  }

  onFileDeleted(file: FileUpload) {
    const newFielList = this.partnerBillForm
      .get('billsUpload')
      ?.value.filter((documents: FileUpload) => documents !== file);

    this.partnerBillForm.get('billsUpload')?.setValue(newFielList);
  }

  onFileListChanged(files: File[]) {
    if (files.length > 0) {
      this.partnerBillForm.get('billsUpload')?.setValue(files);
    } else {
      this.partnerBillForm.get('billsUpload')?.reset();
    }
  }

  onBillSumChange() {
    const sumControl = this.billPositions.controls.find(
      (control) => control.get('name')?.value === 'Nettorechnungssumme',
    );

    if (sumControl) {
      sumControl
        .get('amount')
        ?.setValue(
          PriceNumberFormatHelper.formatInputNetPrice(
            this.partnerBillForm.get('billSum')?.value,
          ),
        );

      this.onAmountChange();
    }
  }

  roundToTwoDecimals(value: number): number {
    // Number.EPSILON wird verwendet, um Rundungsfehler zu vermeiden
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }

  onAmountChange() {
    const sum = this.billPositions.controls.reduce((acc, control) => {
      const amountControl = control.get('amount');
      const nameControl = control.get('name');

      if (!amountControl?.value || amountControl.value === '') {
        amountControl?.setValue(0);
      }

      if (
        amountControl?.valid &&
        nameControl?.value !== 'Nettorechnungssumme'
      ) {
        const formattedAmount = PriceNumberFormatHelper.formatForDB(
          amountControl.value,
        );
        return this.roundToTwoDecimals(acc + formattedAmount);
      }

      return acc;
    }, 0);

    const sumControl = this.billPositions.controls.find(
      (control) => control.get('name')?.value === 'Nettorechnungssumme',
    );

    if (sumControl) {
      const oldSum = this.partnerBillForm.get('billSum')?.value;
      const formattedOldSum = PriceNumberFormatHelper.formatForDB(oldSum);
      const newSum = this.roundToTwoDecimals(formattedOldSum - sum);
      sumControl
        .get('amount')
        ?.setValue(PriceNumberFormatHelper.formatInputNetPrice(newSum));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
