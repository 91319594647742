import { Injectable } from '@angular/core';
import { Email } from '../../models/email';
import { catchError, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { EmailTemplate } from '@lib/models/email-template';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { HttpHandlerService } from '@lib/services/http-handler.service';

interface EmailDataToSend {
  email_type_id: string;
  mail_to: string;
  objects: {
    Email: {
      email_type_id: string;
      mail_to: string;
      mail_from: string;
      mail_from_name: string;
      body: string;
      subject: string;
      lead_id: number;
      lead_type_id: number;
      email_template_id: number;
    };
  };
}

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  endpointSendMail: string = 'lead_edit_send_email';
  endpointEmailPreview: string = 'get_converted_email_template';

  constructor(
    private snackbarService: SnackBarService,
    private httpHandler: HttpHandlerService,
  ) {}

  emailToUser(emailData: Email) {
    const dataToSend: EmailDataToSend = {
      email_type_id: emailData.email_type_id ?? '',
      mail_to: emailData.mail_to ?? '',
      objects: {
        Email: {
          email_type_id: emailData.objects?.email_type_id ?? '',
          mail_to: emailData.objects?.mail_to ?? '',
          mail_from: emailData.objects?.mail_from ?? '',
          mail_from_name: emailData.objects?.mail_from_name ?? '',
          body: emailData.objects?.body ?? '',
          subject: emailData.objects?.subject ?? '',
          lead_id: emailData.objects?.lead_id ?? 0,
          lead_type_id: emailData.objects?.lead_type_id ?? 0,
          email_template_id: emailData.objects?.email_template_id ?? 0,
        },
      },
    };

    return this.httpHandler.create(this.endpointSendMail, dataToSend).pipe(
      tap(() => {
        this.snackbarService.openSnackBar(
          'E-Mail erfolgreich gesendet',
          'success',
        );
      }),
      catchError((error) => {
        this.snackbarService.openSnackBar(
          'Fehler beim Versand der E-Mail',
          'error',
        );
        return throwError(error);
      }),
    );
  }

  getConvertedEmailTemplate(
    templateId: number,
    leadId: number,
  ): Observable<EmailTemplate> {
    const params = new HttpParams().set('lead_id', leadId.toString());

    return this.httpHandler.get(
      this.endpointEmailPreview + '/' + templateId,
      params,
    );
  }
}
