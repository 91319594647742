import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { FilterSetting } from '@lib/models/filter-setting';

import { PartnerLead } from '@lib/models/partner-lead';
import { FilterService } from '@lib/services/filter/filter.service';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { formatCurrency } from '@lib/helpers/numberHelpers';
import { MatDialog } from '@angular/material/dialog';
import { PartnerLeadBackend } from '@lib/models/partners/partner-lead-backend';
import { PartnerLeadBackendService } from '@lib/services/partner/partner-lead-backend.service';
import { PartnerLeadDetail } from '@lib/models/partner-lead-detail';
import { PartnerLeadDragDrop } from '@lib/data/partner-lead-drag-drop';
import { SelectList } from '@lib/models/select-list';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { LeadBackend } from '@lib/models/lead-backend';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-task-board',
  templateUrl: './task-board.component.html',
  styleUrls: ['./task-board.component.scss'],
})
export class TaskBoardComponent implements OnInit, OnDestroy {
  pageTitle = 'Lead-Pipe';
  filter = {
    lead_type_id: [],
    lead_details: [] as any[],
  };
  flags = {
    taskboard: true,
  };
  @ViewChildren('cols') cols!: QueryList<ElementRef>;

  partnerLeads: PartnerLead[] = [];
  partnerLeadBackend: PartnerLeadBackend = new PartnerLeadBackend({});
  partnerLeadDetail: PartnerLeadDetail[] = [];
  partnerLeadDragDrop = PartnerLeadDragDrop;

  filterBarSettings: FilterSetting[] = [];
  amountPartnerWithStatus: number = 1;

  private destroy$ = new Subject<void>();

  constructor(
    private filterService: FilterService,
    private partnerLeadService: PartnerLeadService,
    private snackBarService: SnackBarService,
    private router: Router,
    public dialog: MatDialog,
    private partnerLeadBackendService: PartnerLeadBackendService,
  ) {}

  ngOnInit() {
    this.initFilter();
    this.filterService.filter$
      .pipe(takeUntil(this.destroy$))
      .subscribe((filter) => {
        this.setFilter(filter);
      });
  }

  initFilter() {
    this.filterBarSettings = [
      new FilterSetting({
        filter: SelectList.PartnerLeadDetail,
        multiSelect: true,
      }),
      new FilterSetting({
        filter: SelectList.LeadType,
        multiSelect: true,
      }),
    ];

    this.filterService.getFilter();
  }

  getPartnerLeads() {
    this.partnerLeadService
      .listPartnerLeads({
        filter: this.filter,
        flags: this.flags,
        pageSize: 10000,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerLeads: any) => {
        if (partnerLeads.data) {
          this.partnerLeads = partnerLeads.data;
          this.partnerLeadDragDrop.forEach((element) => {
            const { data, summe } = this.calculateSumForOptionId(
              element.status_fixed,
            );
            element.data = data;
            element.summe = summe;

            if (data.length > this.amountPartnerWithStatus) {
              this.amountPartnerWithStatus = data.length;
            }
          });
          this.setEqualHeight();
        }
      });
  }

  setEqualHeight() {
    // Anwenden der maximalen Höhe auf alle cols
    this.cols.forEach((col: any) => {
      col.nativeElement.style.minHeight = `${
        146 * this.amountPartnerWithStatus
      }px`;
    });
  }

  getLeadsBackends() {
    this.partnerLeadBackendService
      .list({
        filter: {
          active: 1,
          lead_type_id: this.filter.lead_type_id,
          field_type: [5],
        },
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe((leadBackendResponse: LeadBackend) => {
        if (leadBackendResponse) {
          this.partnerLeadBackend = leadBackendResponse;
          let array0Map: any = [];
          if (this.filter.lead_details != undefined) {
            array0Map = this.filter.lead_details.reduce(
              (map: any, item: any) => {
                if (!map[item.lead_field_id]) {
                  map[item.lead_field_id] = {
                    ...item,
                    option_id_arr: [item.option_id],
                  };
                } else {
                  map[item.lead_field_id].option_id_arr.push(item.option_id);
                }
                return map;
              },
              {},
            );
          }
        }
      });
  }

  calculateSumForOptionId(optionId: number): {
    data: PartnerLead[];
    summe: number;
  } {
    const filteredData = this.partnerLeads.filter(
      (partnerLead: PartnerLead) =>
        partnerLead.status_option.option_id == optionId,
    );

    const sum = filteredData.reduce(
      (accumulator: number, currentValue: PartnerLead) => {
        if (currentValue.offer_value !== null) {
          return (
            accumulator + parseInt(currentValue.offer_value.toString(), 10)
          );
        }
        return accumulator;
      },
      0,
    );
    return { data: filteredData, summe: sum };
  }

  setFilter(filter: any) {
    this.filter = {
      ...this.filter, // Kopiert alle vorhandenen Filter
      lead_type_id: filter.filter?.leadTypeId,
      lead_details: filter.filter?.partnerLeadDetail,
    };
    this.getPartnerLeads();
    if (filter.filter?.leadTypeId) {
      this.getLeadsBackends();
    }
  }
  drop(event: CdkDragDrop<PartnerLead[]>, status: number) {
    const currentStatusIndex = event.currentIndex;
    const previousStatusIndex = event.previousIndex;
    const containerData = event.container.data;
    const previousContainerData = event.previousContainer.data;
    if (event.previousContainer === event.container) {
      moveItemInArray(containerData, previousStatusIndex, currentStatusIndex);
    } else {
      transferArrayItem(
        previousContainerData,
        containerData,
        previousStatusIndex,
        currentStatusIndex,
      );
      // get first item of event.container.data
      if (
        containerData.length > 0 &&
        containerData[currentStatusIndex] != undefined &&
        containerData[currentStatusIndex].status_option.option_id != status
      ) {
        containerData[currentStatusIndex].status = status;
        this.partnerLeadService
          .update(containerData[currentStatusIndex])
          .pipe(takeUntil(this.destroy$))
          .subscribe((partnerLead: PartnerLead) => {
            if (partnerLead) {
              const maxCount = Math.max(
                ...this.partnerLeadDragDrop.map(
                  (partnerLeadDragDropItem) =>
                    partnerLeadDragDropItem.data.length,
                ),
              );
              this.amountPartnerWithStatus = maxCount;
              this.setEqualHeight();

              this.partnerLeadService
                .show(partnerLead.id)
                .pipe(takeUntil(this.destroy$))
                .subscribe((partnerLeadResponse: PartnerLead) => {
                  if (partnerLeadResponse) {
                    this.updateOffValueFe(
                      partnerLeadResponse,
                      'transfer_array_item',
                    );
                  }
                });
              this.showSuccessMessage('Status wurde erfolgreich geändert');
            }
          });
      }
    }
  }

  showSuccessMessage(text: string) {
    this.snackBarService.openSnackBar(text, 'success');
  }

  // transferArrayItem or update angebotswert
  updateOffValueFe(partnerLead: PartnerLead, key: string) {
    const index = this.partnerLeads.findIndex(
      (partnerLeadItem: PartnerLead) => partnerLeadItem.id == partnerLead.id,
    );

    if (index !== -1) {
      if (key == 'offer_value') {
        this.partnerLeads[index].offer_value = partnerLead.offer_value;
      } else {
        // update status
        this.partnerLeads[index].status_option = partnerLead.status_option;
        this.partnerLeads[index].status = partnerLead.status;
      }

      this.partnerLeadDragDrop.forEach((element) => {
        const { data, summe } = this.calculateSumForOptionId(
          element.status_fixed,
        );
        element.data = data;
        element.summe = summe;
      });
    }
  }

  updatePartnerLeadOfferValue(partnerLead: any) {
    this.updateOffValueFe(partnerLead, 'offer_value');
  }

  goToLeadDetail(partnerLead: PartnerLead) {
    this.router.navigate(['/leads/edit/', partnerLead.id]);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly formatCurrency = formatCurrency;
  protected readonly SelectList = SelectList;
}
