// select-list.enum.ts
export enum SelectList {
  None = 'None',
  LeadType = 'LeadType',
  Partner = 'Partner',
  LeadStatus = 'LeadStatus',
  PartnerStatus = 'PartnerStatus',
  PartnerLeadStatus = 'PartnerLeadStatus',
  PartnerLeadCancelReason = 'PartnerLeadCancelReason',
  TargetingStatus = 'TargetingStatus',
  LeadDetail = 'LeadDetail',
  LeadResponse = 'LeadResponse',
  PartnerResponse = 'PartnerResponse',
  PartnerBillStatus = 'PartnerBillStatus',
  Domain = 'Domain',
  BillDirection = 'BillDirection',
  BillStatus = 'BillStatus',
  AccountingAccount = 'AccountingAccount',
  PartnerUser = 'PartnerUser',
  PartnerLeadDetail = 'PartnerLeadDetail',
  PartnerAcquisitionStatus = 'PartnerAcquisitionStatus',
  PartnerCancelReason = 'PartnerCancelReason',
  PartnerUserSalesrunner = 'PartnerUserSalesrunner',
  LeadCalledStatus = 'LeadCalledStatus',

  DateRange = 'DateRange',
}
