<app-card>
  <app-card-head [pageTitle]="'Dashboard'">
    <app-filter-bar [filterSettings]="filterBarSettings"></app-filter-bar>
  </app-card-head>
  <app-card-body>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      *ngIf="!performanceTilesLoaded"
    >
      <mat-spinner></mat-spinner>
    </div>

    <div class="text-center" *ngIf="performanceTilesLoaded">
      <div class="row row-cols-3 row-cols-lg-3 g-3 g-lg-3">
        <div
          class="col-4 card px-0"
          *ngFor="let timePeriod of performanceTiles"
        >
          <div class="col" *ngIf="timePeriod.hide == 1">
            <div class="row g-0">
              <div
                class="col-md-2 d-flex align-items-center justify-content-center"
                [class]="timePeriod.class"
                #iconContainer
                style="height: 112px"
              >
                <mat-icon class="text-light">{{ timePeriod.icon }}</mat-icon>
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <span class="d-block fs-6 fw-light fw-bold">
                    <ng-container *ngIf="timePeriod.name == 'revenue'">
                      {{
                        timePeriod.value
                          | dynamicPipe : { name: 'currency', args: ['€'] }
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        timePeriod.name == 'lead_count' ||
                        timePeriod.name == 'success_count' ||
                        timePeriod.name == 'reaction_time_median'
                      "
                    >
                      {{ timePeriod.value }}
                    </ng-container>
                    <ng-container
                      *ngIf="
                        timePeriod.name == 'success_quote' ||
                        timePeriod.name == 'accepted_rate'
                      "
                    >
                      {{
                        timePeriod.value
                          | dynamicPipe : { name: 'percent', args: [''] }
                      }}
                    </ng-container>
                  </span>
                  <span class="d-block fs-7 fw-light">
                    {{ timePeriod.title }}
                  </span>
                  <span class="d-block fs-7 fw-light">
                    <ng-container
                      *ngIf="
                        timePeriod.name == 'success_quote' ||
                        timePeriod.name == 'reaction_time_median' ||
                        timePeriod.name == 'accepted_rate'
                      "
                    >
                      <ng-container *ngIf="timePeriod.ranking === 4">
                        Sie gehören zu den unteren 50%
                      </ng-container>
                      <ng-container *ngIf="timePeriod.ranking === 3">
                        Sie gehören zu den TOP 50%
                      </ng-container>
                      <ng-container *ngIf="timePeriod.ranking === 2">
                        Sie gehören zu den TOP 30%
                      </ng-container>
                      <ng-container *ngIf="timePeriod.ranking === 1">
                        Sie gehören zu den TOP 10%
                      </ng-container>
                    </ng-container>
                  </span>
                </div>
              </div>
              <div
                *ngIf="
                  timePeriod.ranking != null &&
                  timePeriod.ranking > 0 &&
                  timePeriod.ranking != 4
                "
                class="col-md-2 d-flex align-items-center justify-content-center"
                #iconContainer
              >
                <img
                  class="img-responsive px-2"
                  [src]="getRankingTrophy(timePeriod.ranking)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </app-card-body>
</app-card>

<app-card *ngIf="partnerUser?.invoice_and_performance_data === 1">
  <app-card-head [pageTitle]="'Umsatz'"></app-card-head>
  <app-card-body>
    <div
      fxLayout="row"
      fxLayoutAlign="center center"
      *ngIf="!revenueTimelineLoaded"
    >
      <mat-spinner></mat-spinner>
    </div>

    <app-line-chart
      *ngIf="revenueTimelineLoaded"
      [labels]="this.revenueTimelineLabels"
      [datasets]="this.revenueTimelineData"
    ></app-line-chart>
  </app-card-body>
</app-card>

<div class="row">
  <div class="col-6 h-75">
    <app-card>
      <app-card-head [pageTitle]="'Lead Status'"></app-card-head>
      <app-card-body>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngIf="!leadStatusLoaded"
        >
          <mat-spinner></mat-spinner>
        </div>

        <app-pie-chart
          *ngIf="leadStatusLoaded"
          [labels]="this.leadStatusLabels"
          [datasets]="this.leadStatusData"
        ></app-pie-chart>
      </app-card-body>
    </app-card>
  </div>
  <div class="col-6 h-100">
    <app-card>
      <app-card-head [pageTitle]="'Lead erhalten'"></app-card-head>
      <app-card-body>
        <div
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngIf="!leadReceivedLoaded"
        >
          <mat-spinner></mat-spinner>
        </div>

        <app-bar-chart
          *ngIf="leadReceivedLoaded"
          [labels]="this.leadReceivedLabels"
          [datasets]="this.leadReceivedData"
        ></app-bar-chart>
      </app-card-body>
    </app-card>
  </div>
</div>

<div class="row">
  <div class="col-6">
    <app-card>
      <app-card-head [pageTitle]="'Regionale Leadverteilung'"></app-card-head>
      <app-card-body>
        <div style="height: 800px">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            *ngIf="!regionalLeadPlacementsLoaded"
          >
            <mat-spinner></mat-spinner>
          </div>

          <app-map
            *ngIf="regionalLeadPlacementsLoaded"
            [heatmapPoints]="heatMapPoints"
            [mapId]="'map-leads'"
          ></app-map>
        </div>
      </app-card-body>
    </app-card>
  </div>
  <div class="col-6">
    <app-card>
      <app-card-head
        [pageTitle]="'Regionale Abschlussverteilung'"
      ></app-card-head>
      <app-card-body>
        <div style="height: 800px">
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            *ngIf="!regionalSuccessLeadPlacementsLoaded"
          >
            <mat-spinner></mat-spinner>
          </div>

          <app-map
            *ngIf="regionalSuccessLeadPlacementsLoaded"
            [heatmapPoints]="heatMapPointSales"
            [mapId]="'map-sales'"
          ></app-map>
        </div>
      </app-card-body>
    </app-card>
  </div>
</div>

<div class="row">
  <ng-container
    *ngFor="let leadFieldDashboardTable of leadFieldDashboardTables"
  >
    <div class="col-6">
      <app-card>
        <app-card-head [pageTitle]="leadFieldDashboardTable.field.name">
          <span class="">Leadeingangszeitpunkt</span></app-card-head
        >
        <app-card-body>
          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">Wert</th>
                <th scope="col">Anzahl Leads</th>
                <th scope="col">Anzahl Abschlüsse</th>
                <th scope="col">Abschlussrate</th>
                <th
                  *ngIf="partnerUser?.invoice_and_performance_data === 1"
                  scope="col"
                >
                  Umsatz
                </th>
              </tr>
            </thead>
            <tbody *ngFor="let item of leadFieldDashboardTable.data">
              <tr>
                <td scope="row">{{ item.value_name }}</td>
                <td scope="row">{{ item.lead_count }}</td>
                <td scope="row">{{ item.success_count }}</td>
                <td scope="row">{{ item.success_rate | percent }}</td>
                <td
                  *ngIf="partnerUser?.invoice_and_performance_data === 1"
                  scope="row"
                >
                  {{ item.revenue_sum | priceFormat }}
                </td>
              </tr>
            </tbody>
          </table>
        </app-card-body>
      </app-card>
    </div>
  </ng-container>
</div>
