import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'lib-slide-toggle',
  templateUrl: './slide-toggle.component.html',
  styleUrls: ['./slide-toggle.component.scss'],
})
export class SlideToggleComponent implements OnChanges {
  @Input() value: number = 0;
  @Output() valueChange = new EventEmitter<number>();

  booleanValue: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['value']) {
      this.booleanValue = this.value === 1;
    }
  }

  onValueChange() {
    this.value = this.booleanValue ? 1 : 0;
    this.valueChange.emit(this.value);
  }
}
