import { Component, Input, OnDestroy } from '@angular/core';
import { SnackBarService } from '@lib/services/snack-bar/snack-bar.service';
import { DateHelper } from '@lib/helpers/date-helper';
import { PartnerLeadService } from '@lib/services/partner-lead/partner-lead.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-partner-lead-comment',
  templateUrl: './partner-lead-comment.component.html',
})
export class PartnerLeadCommentComponent implements OnDestroy {
  @Input() comment: string = '';
  @Input() partnerLeadId!: number;

  private destroy$ = new Subject<void>();

  constructor(
    private partnerLeadService: PartnerLeadService,
    private snackbarService: SnackBarService,
  ) {}

  onSubmit() {
    this.partnerLeadService
      .showPartnerLead(this.partnerLeadId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((partnerLead) => {
        partnerLead.comment = this.comment;

        this.partnerLeadService
          .update(partnerLead)
          .pipe(takeUntil(this.destroy$))
          .subscribe(
            () => {
              this.snackbarService.openSnackBar(
                'Kommentar erfolgreich aktualisiert.',
                'success',
              );
            },
            () => {
              this.snackbarService.openSnackBar(
                'Angebotswert konnte nicht aktualisiert werden.',
                'warn',
              );
            },
          );
      });
  }

  startCommentWithCurrentDateTime(comment: string) {
    if (comment === '') {
      this.comment = `${DateHelper.getFormattedDateTime(new Date())}:`;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
