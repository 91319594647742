import { Component, Input, OnInit } from '@angular/core';
import { SelectList } from '../../models/select-list';
import { FilterSetting } from '../../models/filter-setting';
import { FilterService } from '../../services/filter/filter.service';
import { DateHelper } from '../../helpers/date-helper';
import { MatDialog } from '@angular/material/dialog';
import { LeadDetailFilterModalComponent } from './lead-detail-filter-modal/lead-detail-filter-modal.component';
import { Option } from '../../models/option';

/**
 * @deprecated Diese Komponente ist veraltet, bitte nutze die lib-filter-bar
 */
@Component({
  selector: 'app-filter-bar',
  templateUrl: './filter-bar.component.html',
  styleUrls: ['./filter-bar.component.scss'],
})
export class FilterBarComponent implements OnInit {
  @Input() filterSettings: FilterSetting[] = [];
  Filter = SelectList;
  leadTypeSelect: number | [] | null = null;
  partnerStatusSelect: number | [] | null = null;
  leadStatusSelect: number | [] | null = null;
  partnerLeadStatusSelect: number | [] | null = null;
  targetingStatusSelect: number | [] | null = null;
  leadDetailSelect: number | [] | null = null;
  partnerSelect: number | [] | null = null;
  leadResponseSelect: number | [] | null = null;
  partnerResponseSelect: number | [] | null = null;
  partnerBillStatusSelect: number | [] | null = null;
  domainSelect: number | [] | null = null;
  billDirectionSelect: number | [] | null = null;
  billStatusSelect: number | [] | null = null;
  accountingAccountSelect: number | [] | null = null;
  partnerUserSelect: number | [] | null = null;
  partnerUserSalesrunnerSelect: number | [] | null = null;
  partnerLeadDetailSelect: number | [] | null = null;
  partnerAcquisitionStatus: number | [] | null = null;
  leadCalledStatusSelect: number = 0;
  dateRange: { start: string; end: string } = {
    start: DateHelper.getStartOfMonth(),
    end: DateHelper.getEndOfMonth(),
  };
  filterRelations: { lead_type_id: any } = { lead_type_id: [] };

  constructor(private filterService: FilterService, public dialog: MatDialog) {}

  protected readonly SelectList = SelectList;

  ngOnInit(): void {
    this.filterService.filter$.subscribe((filter: any) => {
      this.leadTypeSelect = filter.filter?.leadTypeId;
      this.partnerStatusSelect = filter.filter?.partnerStatus;
      this.leadStatusSelect = filter.filter?.leadStatus;
      this.partnerLeadStatusSelect = filter.filter?.partnerLeadStatus;
      this.leadDetailSelect = filter.filter?.leadDetail;
      this.partnerLeadDetailSelect = filter.filter?.partnerLeadDetail;
      this.partnerSelect = filter.filter?.partner;
      this.leadResponseSelect = filter.filter?.leadResponse;
      this.partnerResponseSelect = filter.filter?.partnerResponse;
      this.partnerBillStatusSelect = filter.filter?.partnerBillStatus;
      this.billStatusSelect = filter.filter?.billStatus;
      this.billDirectionSelect = filter.filter?.billDirection;
      this.accountingAccountSelect = filter.filter?.accountingAccount;
      this.partnerAcquisitionStatus = filter.filter?.partnerAcquisitionStatus;
      this.leadCalledStatusSelect = filter.filter?.leadCalledStatus;
      this.dateRange.start = filter?.daterange?.start;
      this.dateRange.end = filter?.daterange?.end;
    });
    // wenn man die Lead Art sich verändert, dann soll Lead Detail auch gesetzt werden
    if (this.leadTypeSelect) {
      this.filterRelations = { lead_type_id: this.leadTypeSelect };
    }
  }

  setDateRange(dateRange: any) {
    this.filterService.setDaterange({
      start: DateHelper.formatDate(dateRange.start),
      end: DateHelper.formatDate(dateRange.end),
    });
  }

  setLeadTypeIdFilter(leadTypeId: any) {
    this.filterService.setFilter({
      // @ts-ignore
      leadTypeId: this.getValuesFromSelectedOption(leadTypeId),
    });
    // nur in Lead-Seite. Wenn man die Lead-Art sich verändert, dann soll Lead Detail auch zurückgesetzt werden

    if (
      this.filterSettings.find(
        (setting) => setting.filter === SelectList.LeadDetail,
      ) ||
      this.filterSettings.find(
        (setting) => setting.filter === SelectList.PartnerLeadDetail,
      )
    ) {
      this.filterRelations = { lead_type_id: this.leadTypeSelect };
    }
  }

  setTargetingStatusFilter(targetingStatus: any) {
    this.filterService.setFilter({
      // @ts-ignore
      targetingStatus: this.getValuesFromSelectedOption(targetingStatus),
    });
  }

  setPartnerAcquiseStatusFilter(partnerAcquisitionStatus: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partnerAcquisitionStatus: this.getValuesFromSelectedOption(
        partnerAcquisitionStatus,
      ),
    });
  }

  setPartnerStatusFilter(partnerStatus: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partnerStatus: this.getValuesFromSelectedOption(partnerStatus),
    });
  }

  setLeadStatusFilter(leadStatus: any) {
    this.filterService.setFilter({
      // @ts-ignore
      leadStatus: this.getValuesFromSelectedOption(leadStatus),
    });
  }

  setPartnerLeadStatusFilter(partnerLeadStatus: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partnerLeadStatus: this.getValuesFromSelectedOption(partnerLeadStatus),
    });
  }

  setPartnerFilter(partner: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partner: this.getValuesFromSelectedOption(partner),
    });
  }

  setDomainFilter(domain: any) {
    this.filterService.setFilter({
      // @ts-ignore
      domains: this.getValuesFromSelectedOption(domain),
    });
  }

  setLeadResponseFilter(leadResponse: any) {
    this.filterService.setFilter({
      // @ts-ignore
      leadResponse: this.getValuesFromSelectedOption(leadResponse),
    });
  }

  setPartnerResponseFilter(partnerResponse: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partnerResponse: this.getValuesFromSelectedOption(partnerResponse),
    });
  }

  setPartnerBillStatusFilter(partnerBillStatus: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partnerBillStatus: this.getValuesFromSelectedOption(partnerBillStatus),
    });
  }

  setLeadDetailFilter(leadDetail: any) {
    this.filterService.setFilter({
      // @ts-ignore
      leadDetail: this.getValuesFromSelectedOption(leadDetail),
    });
  }

  setPartnerLeadDetailFilter(partnerLeadDetail: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partnerLeadDetail: this.getValuesFromSelectedOption(partnerLeadDetail),
    });
  }

  setBillDirectionFilter(billDirection: any) {
    this.filterService.setFilter({
      // @ts-ignore
      billDirection: this.getValuesFromSelectedOption(billDirection),
    });
  }

  setBillStatusFilter(billStatus: any) {
    this.filterService.setFilter({
      // @ts-ignore
      billStatus: this.getValuesFromSelectedOption(billStatus),
    });
  }

  setAccountingAccountFilter(accountingAccount: any) {
    this.filterService.setFilter({
      // @ts-ignore
      accountingAccount: this.getValuesFromSelectedOption(accountingAccount),
    });
  }

  setPartnerUserFilter(partnerUser: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partnerUser: this.getValuesFromSelectedOption(partnerUser),
    });
  }

  setPartnerUserSalesrunnerFilter(partnerUser: any) {
    this.filterService.setFilter({
      // @ts-ignore
      partnerUserSalesrunner: this.getValuesFromSelectedOption(partnerUser),
    });
  }

  setLeadCalledStatusFilter(leadCalledStatus: any) {
    this.filterService.setFilter({
      // @ts-ignore
      leadCalledStatus: this.getValuesFromSelectedOption(leadCalledStatus),
    });
  }

  getValuesFromSelectedOption(selectedOption: any) {
    if (Array.isArray(selectedOption)) {
      return selectedOption.map((option: any) => option.value); //Bei Multiselect ist Selected Option ein Array
    } else {
      return selectedOption ? selectedOption.value : undefined; //Bei Singleselect ist Selected Option ein Objekt
    }
  }

  get displayLeadDetailFilter() {
    return (
      this.leadTypeSelect &&
      (this.leadTypeSelect instanceof Array
        ? this.leadTypeSelect.length > 0
        : true)
    );
  }

  showLeadDetailFilterModal(type: string) {
    let selectedLeadDetails: number | [] | null = null;

    if (type === 'partner') {
      selectedLeadDetails = this.partnerLeadDetailSelect;
    }

    if (type === 'lead') {
      selectedLeadDetails = this.leadDetailSelect;
    }

    const dialogRef = this.dialog.open(LeadDetailFilterModalComponent, {
      width: '95vw',
      maxWidth: '100vw',
      minHeight: '300px',
      data: {
        selectedLeadTypes: this.leadTypeSelect,
        selectedLeadDetails: selectedLeadDetails,
      },
    });

    dialogRef.afterClosed().subscribe((detailFilter) => {
      if (detailFilter?.leadDetails) {
        const options = [];

        for (const leadDetail of detailFilter.leadDetails) {
          const option = new Option();
          option.value = leadDetail;
          options.push(option);
        }

        if (type === 'partner') {
          this.setPartnerLeadDetailFilter(options);
        }
        if (type === 'lead') {
          this.setLeadDetailFilter(options);
        }
      }

      if (detailFilter?.leadTypes) {
        const options = [];

        for (const leadDetail of detailFilter.leadTypes) {
          const option = new Option();
          option.value = leadDetail;
          options.push(option);
        }

        this.setLeadTypeIdFilter(options);
      }
    });
  }
}
