<app-card>
  <app-card-head [pageTitle]="pageTitle">
    <div class="p-1">
      <button
        (click)="onSubmit()"
        class="btn btn-success btn-success"
        *ngIf="!inputsDisabled"
      >
        Speichern
      </button>
    </div>
  </app-card-head>
  <app-card-body>
    <form name="form" (ngSubmit)="(f.form.valid)" #f="ngForm">
      <div class="row">
        <div class="row">
          <div class="mb-2 col-md-2">
            <label class="form-label" for="partnerLead.type_id">Lead Art</label>
            <select
              class="form-control"
              id="partnerLead.type_id"
              name="partnerLead.type_id"
              [(ngModel)]="partnerLead.lead_type.id"
              [disabled]="!!partnerLead.id || inputsDisabled"
            >
              <option disabled value="">Lead Art auswählen</option>
              <option *ngFor="let leadType of leadTypes" [value]="leadType.id">
                {{ leadType.name }}
              </option>
            </select>
          </div>
          <div class="mb-2 col-md-2">
            <label class="form-label" for="status">Status</label>
            <select
              class="form-control"
              id="status"
              name="status"
              [disabled]="inputsDisabled"
              [(ngModel)]="partnerLead.status"
            >
              <option disabled value="">Status auswählen</option>
              <option
                *ngFor="let option of leadStatusOptionList"
                [value]="option.option_id"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
          <div class="mb-2 col-md-2">
            <label for="number" class="form-label">Anfragezeitpunkt</label>
            <input
              type="text"
              class="form-control"
              id="request_at"
              name="request_at"
              [disabled]="true"
              [value]="partnerLead.created_at | date : 'dd.MM.yyyy HH:mm'"
              [ngClass]="{ 'is-invalid': f.submitted && name.errors }"
            />
          </div>
          <div class="mb-2 col-md-3">
            <label for="number" class="form-label">Leadnummer</label>
            <input
              type="text"
              class="form-control"
              id="number"
              name="number"
              [disabled]="true"
              [(ngModel)]="partnerLead.lead_number"
              #name="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && name.errors }"
            />
          </div>
          <div class="mb-2 col-md-3">
            <label for="offer_value" class="form-label">Angebotswert</label>
            <input
              type="text"
              class="form-control"
              id="offer_value"
              name="offer_value"
              placeholder="0 €"
              [(ngModel)]="offerValue"
              (change)="onOfferValueChange()"
              #name="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && name.errors }"
            />
          </div>
        </div>
      </div>
    </form>

    <div class="row" *ngIf="partnerLead?.id">
      <app-partner-lead-backend
        [partnerLead]="partnerLead"
      ></app-partner-lead-backend>
    </div>

    <div class="row">
      <div class="col-md-7">
        <app-card *ngIf="partnerLead?.id">
          <app-card-head pageTitle="Adresse"></app-card-head>
          <app-card-body>
            <app-address
              [address]="this.partnerLead.address"
              [inputsDisabled]="false"
            ></app-address>
          </app-card-body>
        </app-card>

        <ng-container *ngIf="partnerUser$ | async as partnerUser">
          <div
            class="row"
            *ngIf="partnerLead.partner.invoice_and_performance_data == 1"
          >
            <app-partner-bills
              [showCreateButton]="true"
              [partnerLeadId]="partnerLead.id"
              [partnerLeadPrice]="partnerLead.price"
              [pageTitelChange]="'Umsätze'"
            ></app-partner-bills>
          </div>
        </ng-container>
      </div>
      <div class="col-md-5">
        <lib-feed [partnerLead$]="partnerLead$"></lib-feed>
      </div>
    </div>
  </app-card-body>
</app-card>
