<ng-container *ngFor="let filterSetting of filterSettings">
  <div class="p-2" *ngIf="filterSetting.filter === Filter.PartnerStatus">
    <app-select
      [list]="SelectList.PartnerStatus"
      [placeholder]="'Status'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="partnerStatusSelect"
      (valueChanged)="setPartnerStatusFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.LeadType">
    <app-select
      [list]="SelectList.LeadType"
      [placeholder]="'Lead-Arten'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="leadTypeSelect"
      (valueChanged)="setLeadTypeIdFilter($event)"
    ></app-select>
  </div>
  <div
    class="p-2"
    *ngIf="filterSetting.filter === Filter.PartnerAcquisitionStatus"
  >
    <app-select
      [list]="SelectList.PartnerAcquisitionStatus"
      [placeholder]="'Partner Akquise Status'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="partnerAcquisitionStatus"
      (valueChanged)="setPartnerAcquiseStatusFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.DateRange">
    <app-daterange-picker-helper
      [startDate]="dateRange.start"
      [endDate]="dateRange.end"
      (dateRange)="setDateRange($event)"
    ></app-daterange-picker-helper>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.LeadStatus">
    <app-select
      [list]="SelectList.LeadStatus"
      [placeholder]="'Lead Status'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="leadStatusSelect"
      (valueChanged)="setLeadStatusFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.PartnerLeadStatus">
    <app-select
      [list]="SelectList.PartnerLeadStatus"
      [placeholder]="'Lead Status'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="partnerLeadStatusSelect"
      (valueChanged)="setPartnerLeadStatusFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.TargetingStatus">
    <app-select
      [list]="SelectList.TargetingStatus"
      [placeholder]="'Targeting Status'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="targetingStatusSelect"
      (valueChanged)="setTargetingStatusFilter($event)"
    ></app-select>
  </div>
  <div
    class="p-2"
    style="width: 450px"
    *ngIf="filterSetting.filter === Filter.LeadDetail"
  >
    <div fxLayout="row" fxLayoutAlign="end center">
      <app-select
        [list]="SelectList.LeadDetail"
        [placeholder]="'Lead Details'"
        [multiSelect]="filterSetting.multiSelect"
        [disabled]="filterSetting.disabled"
        [selectedValue]="leadDetailSelect"
        [filterRelations]="filterRelations"
        (valueChanged)="setLeadDetailFilter($event)"
      ></app-select>

      <button
        mat-mini-fab
        color="primary"
        class="detail-filter-button"
        (click)="showLeadDetailFilterModal('lead')"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
  </div>
  <div
    class="p-2"
    style="width: 450px"
    *ngIf="filterSetting.filter === Filter.PartnerLeadDetail"
  >
    <div fxLayout="row" fxLayoutAlign="end center">
      <app-select
        *ngIf="displayLeadDetailFilter"
        [list]="SelectList.PartnerLeadDetail"
        [placeholder]="'Lead Details'"
        [multiSelect]="filterSetting.multiSelect"
        [disabled]="filterSetting.disabled"
        [selectedValue]="partnerLeadDetailSelect"
        [filterRelations]="filterRelations"
        (valueChanged)="setPartnerLeadDetailFilter($event)"
      ></app-select>

      <button
        mat-mini-fab
        color="primary"
        class="detail-filter-button"
        (click)="showLeadDetailFilterModal('partner')"
      >
        <mat-icon>filter_list</mat-icon>
      </button>
    </div>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.Partner">
    <app-select
      [list]="SelectList.Partner"
      [placeholder]="'Partner'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="partnerSelect"
      (valueChanged)="setPartnerFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.LeadResponse">
    <app-select
      [list]="SelectList.LeadResponse"
      [placeholder]="'Lead Rückmeldung'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="leadResponseSelect"
      (valueChanged)="setLeadResponseFilter($event)"
    ></app-select>
  </div>
  <div
    style="width: 400px"
    class="p-2"
    *ngIf="filterSetting.filter === Filter.PartnerResponse"
  >
    <app-select
      [list]="SelectList.PartnerResponse"
      [placeholder]="'Partner Rückmeldung'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="partnerResponseSelect"
      (valueChanged)="setPartnerResponseFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.PartnerBillStatus">
    <app-select
      [list]="SelectList.PartnerBillStatus"
      [placeholder]="'Rechnungsstatus'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="partnerBillStatusSelect"
      (valueChanged)="setPartnerBillStatusFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.Domain">
    <app-select
      [list]="SelectList.Domain"
      [placeholder]="'Domain'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="domainSelect"
      (valueChanged)="setDomainFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.BillStatus">
    <app-select
      [list]="SelectList.BillStatus"
      [placeholder]="'Rechnungsstatus'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="domainSelect"
      (valueChanged)="setBillStatusFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.BillDirection">
    <app-select
      [list]="SelectList.BillDirection"
      [placeholder]="'Eingangs-/Ausgangsrechnung'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="domainSelect"
      (valueChanged)="setBillDirectionFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.AccountingAccount">
    <app-select
      [list]="SelectList.AccountingAccount"
      [placeholder]="'Buchhaltungskonto'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="domainSelect"
      (valueChanged)="setAccountingAccountFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.PartnerUser">
    <app-select
      [list]="SelectList.PartnerUser"
      [placeholder]="'Benutzer'"
      [multiSelect]="filterSetting.multiSelect"
      [selectedValue]="partnerUserSelect"
      (valueChanged)="setPartnerUserFilter($event)"
    ></app-select>
  </div>
  <div
    class="p-2"
    *ngIf="filterSetting.filter === Filter.PartnerUserSalesrunner"
  >
    <app-select
      [list]="SelectList.PartnerUser"
      [placeholder]="'Benutzer'"
      [multiSelect]="filterSetting.multiSelect"
      [selectedValue]="partnerUserSalesrunnerSelect"
      (valueChanged)="setPartnerUserSalesrunnerFilter($event)"
    ></app-select>
  </div>
  <div class="p-2" *ngIf="filterSetting.filter === Filter.LeadCalledStatus">
    <app-select
      [list]="SelectList.LeadCalledStatus"
      [placeholder]="'Anrufstatus'"
      [multiSelect]="filterSetting.multiSelect"
      [disabled]="filterSetting.disabled"
      [selectedValue]="partnerStatusSelect"
      (valueChanged)="setLeadCalledStatusFilter($event)"
    ></app-select>
  </div>
</ng-container>
