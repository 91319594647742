import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentService } from './environment.service';
import { Comment } from '../models/comment';
import { Feed } from '../models/feed';
import { CommentSummary } from '../models/comment-summary';

@Injectable({
  providedIn: 'root',
})
export class CommentService {
  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
  ) {}

  getComment(id: number): Observable<Comment> {
    return this.http.get<Comment>(
      `${this.environmentService.apiUrl}comments/${id}`,
    );
  }

  createComment(comment: Comment): Observable<Comment> {
    return this.http.post<Comment>(
      `${this.environmentService.apiUrl}comments`,
      comment,
    );
  }

  updateComment(id: number, comment: Partial<Comment>): Observable<Comment> {
    return this.http.put<Comment>(
      `${this.environmentService.apiUrl}comments/${id}`,
      comment,
    );
  }

  updateCommentSummary(
    id: number,
    comment: Partial<CommentSummary>,
  ): Observable<Comment> {
    return this.http.put<Comment>(
      `${this.environmentService.apiUrl}comment-summary/${id}`,
      comment,
    );
  }

  deleteComment(id: number): Observable<void> {
    return this.http.delete<void>(
      `${this.environmentService.apiUrl}comments/${id}`,
    );
  }

  getCommentsForModel(
    modelType: string,
    modelId: number,
  ): Observable<Comment[]> {
    return this.http.get<Comment[]>(
      `${this.environmentService.apiUrl}comments/${modelType}/${modelId}`,
    );
  }

  getCommentSummaryForModel(
    modelType: string,
    modelId: number,
  ): Observable<Feed[]> {
    return this.http.get<Feed[]>(
      `${this.environmentService.apiUrl}comments/summary/${modelType}/${modelId}`,
    );
  }
}
