import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { Subject } from 'rxjs';
import { NewSelectOption } from '../../models/new-select-option';
import { NewSelectSelection } from '../../models/new-select-selection';
import { SelectList } from '../../models/select-list';
import { SelectService } from '../../services/select.service';

/**
 * Anleitung zur Verwendung des zentralen Selects:
 *
 * 1. Erstelle das Select in deinem Modul:
 *
 * Pflichtfeld:
 *  - options: NewSelectOption[] - das sind die Optionen, die im Select angezeigt werden
 *
 *  Optionale Felder:
 *  - allowNegative: boolean - ob negative Werte erlaubt sind
 *  - multiSelect: boolean - ob mehrere Werte ausgewählt werden können
 *  - placeholder: string - der Platzhaltertext
 *  - clearable: boolean - ob das Select geleert werden kann
 *  - appendClass: string - kann eine beliebige Klasse übergeben
 *  - disabled: boolean - ob das Select deaktiviert ist
 *  - selectedOptions: NewSelectOption | NewSelectOption[] - die vorausgewählten Optionen
 *  - hasNegativeValues: boolean - ob negative Werte vorausgewählt sind
 *  - valueChanged: EventEmitter<NewSelectSelection> - das Event, das ausgelöst wird, wenn sich die Auswahl ändert - ist vom Type: NewSelectSelection
 *
 * Beispiel:
 *
 * <lib-select
 *   [options]="options$ | async"
 *   [allowNegative]="true"
 *   [multiSelect]="true"
 *   [placeholder]="'Test Select'"
 *   (valueChanged)="updateSelectOutput($event)"
 *   [selectedOptions]="[
 *     { value: 6, name: 'Neu', disabled: false },
 *     { value: 9, name: 'Abgebrochen', disabled: false }
 *   ]"
 *   [hasNegativeValues]="true"
 * ></lib-select>
 *
 * Die Daten können in der verwendeten Component so geladen werden:
 *
 * options$: Observable<NewSelectOption[]> = new Observable<NewSelectOption[]>();
 *
 * this.options$ = this.selectService.getSelectOptionsByOptionlistKeyword('lead_status');
 *
 */
@Component({
  selector: 'lib-select',
  templateUrl: './new-select.component.html',
  styleUrls: ['./new-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewSelectComponent implements OnInit, OnChanges, OnDestroy {
  @Input() hasNegativeValues = false;
  @Input() placeholder = '';
  @Input() multiSelect = false;
  @Input() clearable = true;
  @Input() appendClass = '';
  @Input() disabled = false;
  @Input() allowNegative = false;
  @Input() selectList: SelectList = SelectList.None;

  @Input() set options(value: NewSelectOption[] | null) {
    this._options = this.formatOptions(value);
  }
  get options(): NewSelectOption[] | null {
    return this._options;
  }

  @Input() set selectedOptions(
    value: NewSelectOption | NewSelectOption[] | null,
  ) {
    this._selectedOptions = this.formatSelectedOptions(value);
  }
  get selectedOptions(): NewSelectOption | NewSelectOption[] | null {
    return this._selectedOptions;
  }

  @Output() valueChanged = new EventEmitter<NewSelectSelection>();

  ctrlPressed = false;

  private _options: NewSelectOption[] | null = null;
  private _selectedOptions: NewSelectOption | NewSelectOption[] | null = null;
  private destroy$ = new Subject<void>();

  constructor(private selectService: SelectService) {}

  @HostListener('window:keydown.control')
  onCtrlKeyDown() {
    this.ctrlPressed = this.allowNegative;
  }

  @HostListener('window:keyup.control')
  onCtrlKeyUp() {
    this.ctrlPressed = false;
  }

  ngOnInit() {
    this.autoSelectSingleOption();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['options']) {
      this.autoSelectSingleOption();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  select() {
    const selectedOptionsArray = this.getSelectedOptionsArray();
    this.hasNegativeValues = selectedOptionsArray.some(
      () => (this.hasNegativeValues || this.ctrlPressed) && this.allowNegative,
    );

    this.valueChanged.emit({
      options: selectedOptionsArray,
      isNegative: this.hasNegativeValues,
    });
  }

  setSelectedOptions(value: NewSelectOption | NewSelectOption[] | null): void {
    this._selectedOptions = this.formatSelectedOptions(value);
  }

  setOptions(options: NewSelectOption[] | null): void {
    this._options = this.formatOptions(options);
  }

  private autoSelectSingleOption() {
    if (
      this.options?.length === 1 &&
      Array.isArray(this.selectedOptions) &&
      this.selectedOptions.length === 0
    ) {
      this.selectedOptions = this.options[0];
      this.selectService.setSingleOption(this.selectList, true);
      this.select();
    } else {
      this.selectService.setSingleOption(this.selectList, false);
    }
  }

  private getSelectedOptionsArray(): NewSelectOption[] {
    return Array.isArray(this._selectedOptions)
      ? this._selectedOptions
      : this._selectedOptions
      ? [this._selectedOptions]
      : [];
  }

  private formatName(name: string): string {
    return name?.replace(/<br\/?>/g, '\n');
  }

  private formatOptions(
    options: NewSelectOption[] | null,
  ): NewSelectOption[] | null {
    return (
      options?.map((option) => ({
        ...option,
        name: this.formatName(option.name),
      })) ?? null
    );
  }

  private formatSelectedOptions(
    value: NewSelectOption | NewSelectOption[] | null,
  ): NewSelectOption | NewSelectOption[] | null {
    if (value === null) {
      return this.multiSelect ? [] : null;
    }

    if (Array.isArray(value)) {
      if (this.multiSelect) {
        return value
          .filter((v) => v !== null)
          .map((option) => ({ ...option, name: this.formatName(option.name) }));
      } else {
        const foundOption = value.find((v) => v !== null);
        return foundOption ? this.formatSingleOption(foundOption) : null;
      }
    }

    return this.multiSelect
      ? [this.formatSingleOption(value)]
      : this.formatSingleOption(value);
  }

  private formatSingleOption(option: NewSelectOption): NewSelectOption {
    return { ...option, name: this.formatName(option.name) };
  }
}
