export class EmailTemplate {
  id: number = 0;
  active: number = 0;
  email_type_id: any = '';
  lead_type_id: number = 0;
  email_template_id: number = 0;
  body: string = '';
  mail_server: string = '';
  send_after_days: number = 0;
  send_before_days: number = 0;
  sender_email: string = '';
  sender_name: string = '';
  sms_device_id: string = '';
  subject: string = '';
  constructor(emailTemplate: EmailTemplate | {}) {
    Object.assign(this, emailTemplate);
  }
}
