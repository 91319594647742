import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Editor, Toolbar } from 'ngx-editor';
import { Subscription } from 'rxjs';
import { EmailTemplate } from '../../../models/email-template';
import { Lead } from '../../../models/leads/lead';
import { LeadService } from '../../../services/lead/lead.service';
import { ValidationPatterns } from '../../../validations/validation-pattern';
import { DomainHelper } from '../../../helpers/domain-helper';
import { Email } from '../../../models/email';
import { EmailService } from '../../../services/email/email.service';

@Component({
  selector: 'app-email-editor',
  templateUrl: './email-editor.component.html',
  styleUrls: ['./email-editor.component.scss'],
})
export class EmailEditorComponent implements OnInit, OnDestroy {
  selectedEmailTemplate: EmailTemplate = new EmailTemplate({});
  mailForm: FormGroup = new FormGroup({});
  lead: Lead = new Lead({});
  editor: Editor = new Editor();
  private loadLeadSubscription = new Subscription();
  private updateLeadSubscription = new Subscription();

  toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];

  constructor(
    private emailService: EmailService,
    public dialogRef: MatDialogRef<EmailEditorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public inputData: { emailAddress: string },
    private formBuilder: FormBuilder,
    private leadService: LeadService,
  ) {}

  ngOnInit(): void {
    this.editor = new Editor();
    this.initForm();
    this.getLead();
  }

  ngOnDestroy() {
    this.editor.destroy();
    this.loadLeadSubscription.unsubscribe();
    this.updateLeadSubscription.unsubscribe();
  }

  private initForm() {
    this.mailForm = this.formBuilder.group({
      emailTemplate: ['', Validators.required],
      senderName: [{ value: '', disabled: true }, Validators.required],
      senderEmail: [
        { value: '', disabled: true },
        [
          Validators.required,
          Validators.pattern(ValidationPatterns.emailPattern),
        ],
      ],
      recipientEmail: [
        { value: this.inputData.emailAddress, disabled: true },
        [
          Validators.required,
          Validators.pattern(ValidationPatterns.emailPattern),
        ],
      ],
      messageSubject: ['', Validators.required],
      messageBody: ['', Validators.required],
    });
  }

  getLead() {
    this.loadLeadSubscription = this.leadService.leadObservable.subscribe(
      (lead: Lead) => {
        this.updateLeadSubscription = this.leadService
          .update(lead)
          .subscribe(() => {
            this.lead = lead;

            this.mailForm.patchValue({
              senderName: DomainHelper.getCompanyNameByDomain(
                this.lead?.domain,
              ),
              senderEmail: DomainHelper.getEmailByDomain(this.lead?.domain),
            });
          });
      },
    );
  }

  generateEmailData(): Email {
    const bodyContent = this.mailForm.get('messageBody')?.value || '';

    return {
      email_type_id: this.mailForm.get('emailTemplate')?.value || '',
      mail_to: this.mailForm.get('recipientEmail')?.value || '',
      objects: {
        email_type_id: this.mailForm.get('emailTemplate')?.value || '',
        mail_to: this.mailForm.get('recipientEmail')?.value || '',
        mail_from: this.mailForm.get('senderEmail')?.value || '',
        mail_from_name: this.mailForm.get('senderName')?.value || '',
        body: this.insertBodyContent(
          this.selectedEmailTemplate.body,
          bodyContent,
        ),
        subject: this.mailForm.get('messageSubject')?.value || '',
        lead_id: this.lead.id || 0,
        lead_type_id: this.lead.lead_type_id || 0,
        email_template_id: this.selectedEmailTemplate.email_template_id || 0,
      },
    };
  }

  sendMail() {
    if (this.mailForm.valid) {
      const emailData: Email = this.generateEmailData();

      this.emailService.emailToUser(emailData).subscribe((response: any) => {
        this.dialogRef.close();
      });
    }
  }

  onTemplateSelected(selectedTemplateId: number) {
    this.emailService
      .getConvertedEmailTemplate(selectedTemplateId, this.lead.id)
      .subscribe((template: any) => {
        console.log(template);
        this.selectedEmailTemplate = template;
        const updatedContent = this.extractBodyContent(
          this.selectedEmailTemplate?.body || '',
        );
        const updatedSubject = this.selectedEmailTemplate?.subject;

        this.mailForm.patchValue({
          messageSubject: updatedSubject,
          messageBody: updatedContent,
          senderName: this.selectedEmailTemplate?.sender_name,
          senderEmail: this.selectedEmailTemplate?.sender_email,
        });
      });
  }

  private extractBodyContent(html: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const bodyContent = doc.querySelector('#mailtpl_body');
    return bodyContent ? bodyContent.innerHTML : '';
  }

  private insertBodyContent(template: string, content: string): string {
    const parser = new DOMParser();
    const doc = parser.parseFromString(template, 'text/html');
    const bodyContent = doc.querySelector('#mailtpl_body');
    if (bodyContent) {
      bodyContent.innerHTML = content;
    }
    return doc.documentElement.outerHTML;
  }
}
